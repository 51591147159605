import React, { useState, useEffect, useCallback, useMemo } from "react";
import { home } from "../../assets/strings";

import Input from "../elements/Input";
import * as S from "./DataCaptureCard.style";
import Checkbox from "../elements/Checkbox";
import { FluidObject } from "gatsby-image";
import { ModalType } from "../../context/modalContext";
import { validateEmail, validateURL } from "../../utils/validation";

const { dataCaptureModal } = home;

export interface InputProp {
  validation?: ValidationType;
  // TODO: fix hacky
  optionalFieldPlaceholder?: string;
}

interface BaseProps {
  inputProps?: { [index: string]: InputProp };
  image: FluidObject | FluidObject[];
  modalType: ModalType;
  onClick?: (data?: any, index?: number) => void;
  skipFirst?: boolean;
}

export enum ValidationType {
  EMAIL = "email",
  URL = "URL",
}

const DataCaptureCard: React.FC<BaseProps> = (props: BaseProps) => {
  const { image, modalType, inputProps, onClick, skipFirst = false } = props;

  const [itemIndex, setItemIndex] = useState(skipFirst ? 1 : 0);
  const [multiSelectIndices, setMultiSelectIndices] = useState<number[]>([]);
  const [singleSelectIndex, setSingleSelectIndex] = useState<number>();
  const [inputField, setInputField] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState(false);

  const imageWidth = {
    merchantCapture: 13,
    userCapture: 14,
    developerCapture: 12,
    investorCapture: 12,
  }[modalType];

  const root = {
    merchantCapture: dataCaptureModal.merchant,
    userCapture: dataCaptureModal.user,
    developerCapture: dataCaptureModal.developer,
    investorCapture: dataCaptureModal.investor,
  }[modalType];

  const slide = root.slides[itemIndex];

  const shouldCustomise = !!(
    inputProps && Object.keys(inputProps).includes(`${itemIndex}`)
  );
  const inputValidation = shouldCustomise && inputProps[itemIndex]?.validation;
  const optionalFieldPlaceholder =
    shouldCustomise && inputProps[itemIndex]?.optionalFieldPlaceholder;

  useEffect(() => {
    // wipe attr
    setMultiSelectIndices([]);
    setSingleSelectIndex(undefined);
    setInputField("");
    setEmailInputError(false);
  }, [itemIndex]);

  const limit = root.slides.length;

  const hasTitle = !!slide.title;
  const hasBody = !!slide.body;
  const hasInputField = !!slide.inputPlaceholder;
  const hasMultiSelect = !!slide.multiSelectList;
  const hasSingleSelect = !!slide.singleSelectList;

  const isEnabled = useMemo(() => {
    const isInputValid = (() => {
      const defaultValidation = hasInputField && !!inputField.length;
      if (inputValidation === ValidationType.EMAIL)
        return defaultValidation && validateEmail(inputField);
      if (inputValidation === ValidationType.URL)
        return defaultValidation && validateURL(inputField);
      return defaultValidation;
    })();
    const isMultiSelectValid = hasMultiSelect && !!multiSelectIndices.length;
    const isSingleSelectValid =
      hasSingleSelect && singleSelectIndex !== undefined;
    return isInputValid || isMultiSelectValid || isSingleSelectValid;
  }, [inputField, multiSelectIndices, singleSelectIndex]);

  const goToNextItem = useCallback(() => setItemIndex(itemIndex + 1), [
    itemIndex,
  ]);

  const addMultiSelectIndex = useCallback(
    (index: number) => {
      setMultiSelectIndices([...multiSelectIndices, index]);
    },
    [multiSelectIndices]
  );

  const removeMultiSelectIndex = useCallback(
    (index: number) => {
      setMultiSelectIndices(multiSelectIndices.filter((i) => i != index));
    },
    [multiSelectIndices]
  );

  const handleMultiSelectChange = (index: number) => {
    if (multiSelectIndices.includes(index)) {
      removeMultiSelectIndex(index);
    } else {
      addMultiSelectIndex(index);
    }
  };

  const handleSingleSelectChange = useCallback(
    (index: number) => setSingleSelectIndex(index),
    [singleSelectIndex]
  );

  const renderTitle = () =>
    hasTitle && (
      <>
        <h2>{slide.title}</h2>
        {renderSubtitle()}
        <S.Spacer />
      </>
    );

  const renderSubtitle = () => hasTitle && <p>{slide.subtitle}</p>;

  const renderBody = () =>
    hasBody && <p dangerouslySetInnerHTML={{ __html: slide.body }} />;

  const renderInputField = () =>
    hasInputField && (
      <>
        <Input
          placeholder={slide.inputPlaceholder}
          value={inputField}
          onChange={(e) => setInputField(e.target.value)}
        />
        <S.ErrorMessage isHidden={!emailInputError}>
          That email doesn't look quite right
        </S.ErrorMessage>
      </>
    );

  const renderCheckbox = ({ item, isCircle, isChecked, onChange }: any) => (
    <div>
      <S.CheckboxLabel>
        <Checkbox isCircle={isCircle} checked={isChecked} onChange={onChange} />
        <span>{item}</span>
      </S.CheckboxLabel>
    </div>
  );

  const renderMultiSelect = () =>
    hasMultiSelect && (
      <div>
        {slide.multiSelectList.map((item, i): any => {
          const isCircle = false;
          const isChecked = !!multiSelectIndices.includes(i);
          const onChange = () => handleMultiSelectChange(i);
          return renderCheckbox({ item, i, isCircle, isChecked, onChange });
        })}
        {!!optionalFieldPlaceholder && (
          <S.SelectInput
            placeholder={optionalFieldPlaceholder}
            value={inputField}
            onChange={(e) => setInputField(e.target.value)}
          />
        )}
      </div>
    );

  const renderSingleSelect = () =>
    hasSingleSelect && (
      <div>
        {slide.singleSelectList.map((item, i): any => {
          const isCircle = true;
          const isChecked = singleSelectIndex === i;
          const onChange = () => handleSingleSelectChange(i);
          return renderCheckbox({ item, i, isCircle, isChecked, onChange });
        })}
      </div>
    );

  const handleCTAClick = () => {
    const mappedMultiSelectData = ((): string[] | undefined => {
      if (!multiSelectIndices.length) return;
      return multiSelectIndices.map((i) => slide.multiSelectList[i]);
    })();

    const mappedSingleSelectData = ((): string | undefined => {
      if (!singleSelectIndex) return;
      return slide.singleSelectList[singleSelectIndex];
    })();

    const data = {
      multiSelect: mappedMultiSelectData,
      singleSelect: mappedSingleSelectData,
      inputField: inputField,
    };
    onClick(data, itemIndex);
    goToNextItem();
  };

  return (
    <S.Wrapper>
      <S.LeftContainer>
        <S.Image
          fluid={image}
          imgStyle={{ objectFit: "contain" }}
          maxWidth={imageWidth}
          fadeIn={false}
          loading={"eager"}
        />
        <h2>{root.heading}</h2>
        <p>{root.subheading}</p>
      </S.LeftContainer>
      <S.RightContainer>
        {renderTitle()}
        {renderBody()}
        {renderMultiSelect()}
        {renderSingleSelect()}
        {renderInputField()}
        {itemIndex !== limit - 1 && (
          <S.ButtonWrapper>
            <S.Button
              title={slide.ctaTitle}
              onClick={handleCTAClick}
              disabled={!isEnabled}
            />
          </S.ButtonWrapper>
        )}
      </S.RightContainer>
    </S.Wrapper>
  );
};

export default DataCaptureCard;
