import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { ModalType } from "../../../context/modalContext";
import DataCaptureCard from "../DataCaptureCard";
import { track } from "../../../utils/user";

const DeveloperCaptureModal: React.FC = () => {
  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "illustration-data-modal-developer.png" }
      ) {
        ...modalIllustration
      }
    }
  `);

  const handleOnClick = (d?: any, index?: number) => {
    track("Form Submitted", { UserSegment: "developer" });
  };

  return (
    <DataCaptureCard
      modalType={ModalType.DeveloperCapture}
      image={illustration.childImageSharp.fluid}
      onClick={handleOnClick}
    />
  );
};

export default DeveloperCaptureModal;
