import styled from "styled-components";
import { screenSize } from "../../utils/theme";
import CurveBackground from "../../assets/svg/bg-about-curve.svg";

export const Wrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  grid-column: 1/17;
  overflow: hidden;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(16, 1fr);
  background: url(${CurveBackground}) no-repeat;
  background-position-y: 45%;
  background-size: contain;
`;

export const Heading = styled.h4`
  text-align: center;
  grid-column: 1/17;
`;

export const MemberContainer = styled.div<{ isFirst?: boolean }>`
  ${({ theme }) => theme.mainGridWithPadding};

  min-height: initial !important;
  display: flex;
  justify-content: center;
`;

export const Members = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;

  @media all ${screenSize.M_MIN} {
    max-width: 780px;
  }

  @media all ${screenSize.L_MIN} {
    max-width: 1440px;
    padding: 0;
  }
`;
