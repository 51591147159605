import React, { useLayoutEffect, useState } from "react";
import * as S from "./MiddleStreamSection.style";
import { home } from "../../assets/strings";
import { useInView } from "react-hook-inview";
import Lottie, { Options } from "react-lottie";
import streamAnimation from "../../assets/lottie/middle-stream.json";

const MiddleStreamSection: React.FC = () => {
  const [shouldLottiePlay, setLottiePlay] = useState(false);

  const [ref, inView] = useInView({
    unobserveOnEnter: true,
    threshold: 0.75,
  });

  useLayoutEffect(() => {
    setLottiePlay(inView);
  }, [inView]);

  const defaultOptions: Options = {
    loop: true,
    autoplay: false,
    animationData: streamAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <S.Wrapper ref={ref}>
      <Lottie
        options={defaultOptions}
        height={"auto"}
        width={1440}
        isPaused={!shouldLottiePlay}
      />
    </S.Wrapper>
  );
};

export default MiddleStreamSection;
