import React from "react";
import * as S from "./TeamSection.style";
import { about } from "../../assets/strings";
import TeamCard from "../../components/TeamCard";
import { graphql, useStaticQuery } from "gatsby";
import { FluidImage } from "../../types";
import { SocialLink } from "../../components/elements/SocialIcons";

const { team: root } = about;

type TeamQuery = {
  francesco: FluidImage;
  miao: FluidImage;
  michele: FluidImage;
  nuno: FluidImage;
  mehul: FluidImage;
  jade: FluidImage;
  markus: FluidImage;
  kaspar: FluidImage;
  didi: FluidImage;
  vincent: FluidImage;
  sam: FluidImage;
  rhys: FluidImage;
};

interface TeamMember {
  imgKey: keyof TeamQuery;
  name: string;
  role: string;
  social?: SocialLink[];
}

const query = graphql`
  query {
    francesco: file(relativePath: { eq: "team-fran.png" }) {
      ...teamMemberPhoto
    }
    miao: file(relativePath: { eq: "team-miao.png" }) {
      ...teamMemberPhoto
    }
    michele: file(relativePath: { eq: "team-mike.png" }) {
      ...teamMemberPhoto
    }
    nuno: file(relativePath: { eq: "team-nuno.png" }) {
      ...teamMemberPhoto
    }
    mehul: file(relativePath: { eq: "team-mehul.png" }) {
      ...teamMemberPhoto
    }
    jade: file(relativePath: { eq: "team-jade.png" }) {
      ...teamMemberPhoto
    }
    didi: file(relativePath: { eq: "team-didi.png" }) {
      ...teamMemberPhoto
    }
    markus: file(relativePath: { eq: "team-markus.png" }) {
      ...teamMemberPhoto
    }
    kaspar: file(relativePath: { eq: "team-kaspar.png" }) {
      ...teamMemberPhoto
    }
    vincent: file(relativePath: { eq: "team-vincent.png" }) {
      ...teamMemberPhoto
    }
    sam: file(relativePath: { eq: "team-sam.png" }) {
      ...teamMemberPhoto
    }
    rhys: file(relativePath: { eq: "team-rhys.png" }) {
      ...teamMemberPhoto
    }
    cesar: file(relativePath: { eq: "team-extra-cesar.png" }) {
      ...teamMemberPhoto
    }
    vivi: file(relativePath: { eq: "team-extra-vivi.png" }) {
      ...teamMemberPhoto
    }
    damola: file(relativePath: { eq: "team-extra-damola.png" }) {
      ...teamMemberPhoto
    }
    anett: file(relativePath: { eq: "team-extra-anett.png" }) {
      ...teamMemberPhoto
    }
    patrick: file(relativePath: { eq: "team-extra-patrick.png" }) {
      ...teamMemberPhoto
    }
    chris: file(relativePath: { eq: "team-extra-chris.png" }) {
      ...teamMemberPhoto
    }
  }
`;

const team: TeamMember[] = [
  {
    imgKey: "francesco",
    name: "Francesco",
    role: "Co-founder & CEO",
    social: [
      {
        type: "twitter",
        attr: "FrancescoRenziA",
      },
      {
        type: "github",
        attr: "kobuta23",
      },
      {
        type: "linkedin",
        attr: "in/francescogeorgerenzi",
      },
    ],
  },
  {
    imgKey: "miao",
    name: "Miao",
    role: "Co-founder & CTO",
    social: [
      {
        type: "github",
        attr: "hellwolf",
      },
      {
        type: "linkedin",
        attr: "in/miaozc",
      },
    ],
  },
  {
    imgKey: "michele",
    name: "Michele",
    role: "Co-founder & COO",
    social: [
      {
        type: "twitter",
        attr: "MicheleDaliessi",
      },
      {
        type: "github",
        attr: "mdalie",
      },
      {
        type: "linkedin",
        attr: "in/micheledaliessi",
      },
    ],
  },
  {
    imgKey: "nuno",
    name: "Nuno",
    role: "Web3 Full Stack Engineer",
    social: [
      // {
      //   type: "twitter",
      //   attr: "Logicb0x",
      // },
      // {
      //   type: "github",
      //   attr: "ngmachado",
      // },
      // {
      //   type: "linkedin",
      //   attr: "in/ngmachado",
      // },
    ],
  },
  {
    imgKey: "mehul",
    name: "Mehul",
    role: "Full Stack Engineer",
    social: [
    //   {
    //     type: "twitter",
    //     attr: "MehulSoni89",
    //   },
    //   {
    //     type: "github",
    //     attr: "mehul-soni-7200aa31",
    //   },
    //   {
    //     type: "linkedin",
    //     attr: "in/msoni89",
    //   },
    ],
  },
  {
    imgKey: "jade",
    name: "Jade",
    role: "Executive Assistant",
    social: [
      // {
      //   type: "linkedin",
      //   attr: "in/jade-abigail-southern-50607a71",
      // },
    ],
  },
  {
    imgKey: "didi",
    name: "Didi",
    role: "Web3 Full Stack Engineer",
    social: [
    ],
  },
  {
    imgKey: "markus",
    name: "Markus",
    role: "Lead Dev Tooling Engineer",
    social: [
      // {
      //   type: "linkedin",
      //   attr: "in/markus-arro-33752918a",
      // },
    ],
  },
  {
    imgKey: "kaspar",
    name: "Kaspar",
    role: "Full Stack Engineer",
    social: [
      // {
      //   type: "github",
      //   attr: "kasparkallas",
      // },
      // {
      //   type: "linkedin",
      //   attr: "in/kaspar-kallas-759345181",
      // },
    ],
  },
  {
    imgKey: "vincent",
    name: "Vincent",
    role: "Shadowy Super Coder",
    social: [
      // {
      //   type: "linkedin",
      //   attr: "in/vincent-kh-chee/",
      // },
    ],
  },
  {
    imgKey: "sam",
    name: "Sam",
    role: "Developer Experience Manager",
    social: [
    ],
  },
  {
    imgKey: "rhys",
    name: "Rhys",
    role: "Marketing Manager",
    social: [
    ],
  },
];

const getImageByKey = (queryResult: TeamQuery) => (key: keyof TeamQuery) =>
  queryResult[key].childImageSharp.fluid;

const TeamSection: React.FC = () => {
  const getAvatar = getImageByKey(useStaticQuery<TeamQuery>(query));

  return (
    <S.Wrapper>
      <S.Heading>{root.title}</S.Heading>
      <S.MemberContainer isFirst={true}>
        <S.Members>
          {team.map((member) => (
            <TeamCard {...member} image={getAvatar(member.imgKey)} />
          ))}
        </S.Members>
      </S.MemberContainer>
    </S.Wrapper>
  );
};

export default TeamSection;
