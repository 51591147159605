export const validateEmail = (text: string): boolean => {
  // maybe swap to regex ... ?
  if (text === "") return false;

  const lastAtPos = text.lastIndexOf("@");
  const lastDotPos = text.lastIndexOf(".");

  return (
    lastAtPos < lastDotPos &&
    lastAtPos > 0 &&
    text.indexOf("@@") == -1 &&
    lastDotPos > 2 &&
    text.length - lastDotPos > 2
  );
};

export const validateURL = (text: string): boolean => {
  let re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return !!text.match(re);
};
