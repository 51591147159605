import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { home } from "../../assets/strings";
import * as S from "./DashboardSection.style";
import Link from "../../components/elements/Link";
import Button from "../../components/elements/Button";

const { dashboardSection: root } = home;

const query = graphql`
  query {
    illustration: file(relativePath: { eq: "illustration-dashboard.png" }) {
      ...dashboardIllustration
    }
  }
`;

const DashboardSection: React.FC = () => {
  const { illustration } = useStaticQuery(query);

  return (
    <S.Wrapper>
      <S.Donate>
        <h2 className="title">{root.title}</h2>
        <p className="subtitle">{root.subtitle}</p>
      </S.Donate>
      <S.Illustration
        fluid={illustration.childImageSharp.fluid}
        imgStyle={{ objectFit: "contain" }}
        fadeIn={false}
        loading={"eager"}
      />
      <Link href={root.ctaUrl} target="_blank">
        <Button title={root.ctaButtonTitle} onClick={() => {}} />
      </Link>
    </S.Wrapper>
  );
};

export default DashboardSection;
