import React from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";

interface Props {
  image: string;
}

const IconBox: React.FC<Props> = ({ children, image }) => {
  return (
    <StyledIconBox>
      <div className="icon">
        <img src={image} alt="icon" />
      </div>
      {children}
    </StyledIconBox>
  );
};

const StyledIconBox = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;

  p {
    padding: 0 0 0 0.5rem;
    line-height: 1.6rem;
    margin: 0;
  }

  img {
    width: 3.75rem;
  }

  @media all ${screenSize.L_MIN} {
    margin-left: 0;
    flex-direction: row;
  }
`;

export default IconBox;
