import { pages } from "../../pageConfig";

const discordURL = "https://discord.gg/q5Y5Cxc";

export interface FooterLink {
  text: string;
  url?: string;
  onClickIndex?: number;
}

export const components = {
  createDonationBox: {
    title: "Create a donation link.",
    donationTitle: "Donation Address",
    donationInputPlaceholder: "ENS name or ETH Address",
    amountTitle: "Suggested Amount",
    donationPeriod: "/ month",
    ctaTitle: "Create Now",
    noRegisterText: "You won’t be asked to log in/register.",
  },
  subscriptionBox: {
    demoOnlyText: "Demo only. Try it out.",
  },
};

export const home = {
  meta: {
    title: "Superfluid",
    description:
      "Handle subscriptions, salaries, rewards and any composable stream of value, with continuous settlement and per-second netting for extreme capital efficiency.",
    path: pages.home,
  },
  getStartedSection: {
    logoAltTitle: "Superfluid Logo",
    headerButtonTitle: "Get Early Access",
    flowTitle: "Discover Programmable Cashflows",
    flowSubtitle:
      "Handle subscriptions, salaries, rewards and any composable stream of value, with continuous settlement and per-second netting for extreme capital efficiency.",
    ctaButtonTitle: "Get Started",
    inputPlaceholder: "Type your email",
    inputMessage:
      "By entering your email you accept our Privacy Policy and Terms of Use.",
    inputError: "That email doesn't look quite right...",
    flowItems: [
      {
        title: "Gasless Transfers",
        content:
          "Once a stream is opened, it keeps flowing every second without needing additional transactions.",
        alt: "Money",
      },
      {
        title: "No capital lockups",
        content:
          "Superfluid works with just-in-time liquidity. You only need enough balance to keep your streams open.",
        alt: "Padlock",
      },
      {
        title: "On-chain",
        content:
          "Superfluid lives on EVM networks, allowing full composability and no delays when withdrawing.",
        alt: "Network",
      },
    ],
  },
  protocolSection: {
    next: "-> Next",
    previous: "<- previous",
    tryDemo: "try a demo ->",
    backToOverview: "back to overview ->",
    footer:
      "Want a deep dive? <span>Check out our <a href='https://docs.superfluid.finance/'>docs</a></span>",
    slides: {
      A: {
        title: "Protocol.",
        descriptions: [
          "Superfluid is a new <span>token standard,</span> with the power to describe cashflows, and execute them automatically on chain over time in a non-interactive way.",
          "Superfluid flows are programmable, composable and modular. Our first cashflow types allow <span>constant streams of value,</span> and one-to-many distributions.",
          "All flows are settled at the same time, based on block timestamps. This makes it possible to net inflows and outflows, increasing <span>capital efficiency.</span>",
        ],
      },
      B: {
        title: "Token.",
        descriptions: [
          "Upgrading your tokens allows for legacy ERC20s to get access to <a href='#'>Superfluid functionalities</a>.",
          "Upgraded tokens are also ERC20 compatible, and visible in your wallet at all time",
          "You can convert your tokens any time, and get back your legacy tokens 1:1",
        ],
      },
      C: {
        title: "Real-Time Balance.",
        descriptions: [
          "A real-time balance is the amount of money you have available in your account. Differently to your normal balance, it can handle incoming and outgoing streams, so it <span>updates automatically</span> as time goes by.",
        ],
      },
      D: {
        descriptions: [
          "A real time balance updates over time if you send or receive cashflows and updates instantly if you send regular transactions.",
          "These cashflows feed directly into your balance, and this is why it moves over time.",
        ],
        balance: "Real-Time Balance.",
      },
      E: {
        boxes: [
          {
            title: "Constant Flow Agreement",
            subtitle: "rate * time",
            unit: "/ second",
            more: "Learn more",
          },
          {
            title: "Instant Distribution Agreement",
            subtitle: "units * index",
            unit: "* index",
            more: "Learn more",
          },
          {
            title: "Static Balance",
          },
          {
            title: "Other Agreements",
          },
        ],
      },
      F: {
        box: {
          title: "Constant Flow Agreement",
          subtitle: "rate * time",
          input: "/ second",
        },
        descriptions: [
          "A constant flow agreement is a stream of money that is invariable over time.",
          "Users can have inflows (streams coming in) and outflows (streams going out) from the same account, and their <span>net flow</span> is the difference between incoming and outgoing",
          "There is no limit to the number of flows an account can handle!",
        ],
      },
      G: {
        staticBoxTitle: "Static Balance",
        flowBoxTitle: "Flow Agreement",
        bobReceivingTextA: "Bob is receiving",
        bobReceivingTextB: "$/second. ",
        bobReceivingTextC:
          "The net flow rate is the sum of all incoming and outgoing flows.",
        bobReceivingTextD: "The timer is reset every time something is updated",
        lastUpdateTitle: "Last update",
        lastUpdateUnit: "seconds ago",
        netflowRateTitle: "Net Flow Rate",
        netflowRateUnit: "$ / second",
        createFlowTitle: "Create Flow",
        bobStreamTextA: "Bob is receiving 20$/s from Mike and John.",
        bobStreamTextB: "Bob can open and close new streams at will.",
        bobStreamTextC:
          "If his money runs out, his flows will be closed automatically by the system.",
      },
      H: {
        idaBoxTitle: "Instant Distribution Agreement",
        idaBoxSubtitle: "units * index",
        idaBoxUnit: "* index",
        quoteText:
          "The Instant Distribution Agreement is an efficient method of transferring funds from one sender to a predefined set of receivers.",
        oneWayText:
          "It is a one-way pipe, and can only send money in one direction.",
        depositTextA:
          "By depositing any quantity to a smart contract, the index is updated, distributing money to multiple users at a time with a fixed gas price, based on their proportional amount of",
        depositTextB: "shares",
      },
      I: {
        staticInputTitle: "Static Balance",
        idaInputTitle: "Instant Distribution Agreement",
        bobTextA: "Units represent your proportion of the total.",
        bobTextB: "The Index keeps track of how much you are owed.",
        bobInputTitle: "My Units",
        indexInputTitle: "Index",
        potatoText:
          "The amount of rewards you receive depends on your proportion of the total units.",
        rewardsInputTitle: "Rewards Amount",
        totalInputTitle: "Total Issued Units",
      },
    },
  },
  donateSection: {
    title: "Donate, per second.",
    subtitle:
      "Start accepting streaming donations.<br />Constant liquidity for your project.<br />Complete control for your donors!",
  },
  subscriptionSection: {},
  dashboardSection: {
    title: "All your streams in one place",
    subtitle: "Meet the superfluid dashboard",
    ctaButtonTitle: "Go to Dashboard",
    ctaUrl: "https://app.superfluid.finance",
  },
  getStartedModal: {
    items: [
      {
        title: "Get paid in streams",
        subtitle: "Start accepting recurring payments in crypto.",
        ctaTitle: "Sign Up",
      },
      {
        title: "Manage your streams",
        subtitle: "All your recurring payments in one place, in streams.",
        ctaTitle: "Go to dashboard",
      },
      {
        title: "Build Real-Time Finance",
        subtitle: "Build more powerful DeFi apps using Superfluid Protocol",
        ctaTitle: "Read the Docs",
      },
    ],
  },
  footer: {
    logoAltTitle: "Superfluid Logo",
    tempTitle: "More Coming Soon!",
    tempSubtitle: "Help us build Real-time Finance",
    message:
      "We are on a mission to transform the way assets move in decentralized finance.",
    twitterAltTitle: "Twitter",
    linkedInAltTittle: "LinkedIn",
    githubAltTitle: "Github",
    inputPlaceholder: "Type your email",
    subscribeButtonTitle: "Get Early Access",
    inputMessage:
      "By entering your email you accept our Privacy Policy and Terms of Use.",
    inputError: "That email doesn't look quite right...",
    sections: [
      {
        title: "Protocol",
        list: <FooterLink[]>[
          {
            text: "Docs",
            url: "https://docs.superfluid.finance/",
          },
          {
            text: "GitHub",
            url: "https://github.com/orgs/superfluid-finance/",
          },
          {
            text: "Bug Bounty",
            url: "https://immunefi.com/bounty/superfluid/",
          },
        ],
      },
      {
        title: "Learn",
        list: <FooterLink[]>[
          {
            text: "Intro to Superfluid",
            url:
              "https://medium.com/superfluid-blog/the-dawn-of-programmable-cashflows-2d50edae05cb",
          },
          {
            text: "What is a stream?",
            url:
              "https://medium.com/superfluid-blog/superfluid-streams-5cc5141dd8a7",
          },
          {
            text: "Community Hacks",
            url:
              "https://medium.com/superfluid-blog/superfluid-hacks-1-0-2945b21fd41e",
          },
        ],
      },
      {
        title: "Company",
        list: <FooterLink[]>[
          {
            text: "About",
            url: "/about",
          },
          {
            text: "Blog",
            url: "https://medium.com/superfluid-blog",
          },
          {
            text: "Careers",
            url: "http://jobs.superfluid.finance/",
          },
        ],
      },
      {
        title: "Resources",
        list: <FooterLink[]>[
          {
            text: "Reactor",
            url: "/reactor"
          },
          {
            text: "Support Chat",
            onClickIndex: 0,
          },
          {
            text: "Investors",
            onClickIndex: 1,
          },
        ],
      },
    ],
  },
  dataCaptureModal: {
    merchant: {
      heading: "Handle subscriptions",
      subheading: "Start accepting seamless recurring payments, in crypto!",
      slides: [
        {
          title: "Get Early Access 😎",
          subtitle: undefined,
          body: "Add your email below so that we can get in touch",
          inputPlaceholder: "Type your email",
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body:
            "Please add the url of your  website, shop or channel where you want to accept crypto payments",
          inputPlaceholder: "https://superfluid.finance",
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "How do your customers  pay you normally?",
          inputPlaceholder: undefined,
          multiSelectList: [
            "With recurring subscriptions",
            "With single, one-off transactions",
          ],
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "How do your customers  pay you normally?",
          inputPlaceholder: undefined,
          multiSelectList: ["ETH", "DAI", "USDc", "USDt", "Other, specify:"],
          ctaTitle: "Next",
        },
        {
          title: "All set! ✌️",
          subtitle: "We have received your request.",
          body:
            "Keep an eye on your inbox, our team will get in touch soon via the email you provided!",
          inputPlaceholder: undefined,
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
      ],
    },
    user: {
      heading: "Full control",
      subheading: "Be among the first to try our new protocol interface",
      slides: [
        {
          title: "All set! ✌️",
          subtitle: "We have received your request.",
          body: `
            Keep an eye on your inbox, our team will get in touch soon via the email you provided!
            <br/><br/>
            In the meantime, join our community channel on <a href="${discordURL}">Discord</a>.
            `,
          inputPlaceholder: undefined,
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
      ],
    },
    developer: {
      heading: "Build Real-time Finance",
      subheading: "Be among the first to try our new protocol interface",
      slides: [
        {
          title: "Let’s build! ✌️",
          subtitle: undefined,
          body: `Our Protocol docs are still in the making, check what’s available <a href="https://docs.superfluid.finance/">here</a> and on <a href="https://github.com/superfluid-finance">GitHub</a>.<br /> <br /> Or help us improve the code or documentation, by joining our community channel on <a href="${discordURL}">Discord</a>.`,
          inputPlaceholder: undefined,
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
      ],
    },
    investor: {
      heading: "Real-time finance",
      subheading: "We unlock idle capital for highly efficient markets",
      slides: [
        {
          title: "We would love to tell you more",
          subtitle: undefined,
          body: "Add your email below so that we can get in touch",
          inputPlaceholder: "Type your email",
          checkList: undefined,
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "How would you describe your investment activity?",
          inputPlaceholder: undefined,
          singleSelectList: [
            "Angel Investor",
            "Family Office",
            "Venture Capital",
            "Corporate Venture Capital",
            "Private Equity",
          ],
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "What's your ideal investment stage?",
          inputPlaceholder: undefined,
          multiSelectList: [
            "Pre-Seed",
            "Seed",
            "Series A",
            "Series B",
            "Series C, D, E and Beyond",
          ],
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "Do you invest in equity or tokens?",
          inputPlaceholder: undefined,
          singleSelectList: [
            "Equity only",
            "Tokens only",
            "Both equity and tokens",
          ],
          ctaTitle: "Next",
        },
        {
          title: undefined,
          subtitle: undefined,
          body: "What is the fund size?",
          inputPlaceholder: undefined,
          singleSelectList: [
            "Less than $50M",
            "$50M - $200M",
            "$200M - $500M",
            "More than $500M",
          ],
          ctaTitle: "Next",
        },
        {
          title: "All set! ✌️",
          subtitle: "We have received your request.",
          body:
            "Keep an eye on your inbox, our team will get in touch soon via the email you provided!",
          inputPlaceholder: undefined,
          multiSelectList: undefined,
          ctaTitle: "Next",
        },
      ],
    },
  },
};

export const about = {
  meta: {
    title: "Superfluid",
    description:
      "Handle subscriptions, salaries, rewards and any composable stream of value, with continuous settlement and per-second netting for extreme capital efficiency.",
    path: pages.about,
  },
  branding: {
    logoAltTitle: "Superfluid Logo",
    title:
      "Superfluid is on a mission to transform the way assets move in decentralized finance.",
    twitter: "@superfluid_hq",
  },
  team: {
    title: "Our team",
    extraTitle: "Awesome people that help us:",
  },
  joinTeam: {
    title: "We are making it happen…",
    subtitle: "Want to build the future of Real-Time Finance together?",
    ctaTitle: "Join us",
  },
};

export const reactor = {
  meta: {
    title: "Superfluid Reactor",
    description: "Superfluid Reactor is an acceleration program focused on helping high-potential projects building with Superfluid Protocol launch as independent ventures."
  },
  hero: {
    title: `Hypercharge\nyour web3 project`,
    description: "We are helping high-potential projects built on Superfluid to launch as independent Web3 ventures",
    cta: "Apply Now",
    ctaLink: "https://airtable.com/shrSvpcFTdtx2XpNE"
  },
  overview: {
    title: "How does it work?",
    A: "We keep an eye on all projects <span>building with Superfluid</span> technology, paying attention to the team quality, product usefulness, market potential, and ecosystem impact.",
    B: "We select the <span>best performing teams</span> and help them refine their product specifications and value proposition, enhance their marketing efforts, connect them to Tier 1 clients, and introduce them to value-add investors in the web3 space.",
    C: "<span>No equity</span> is taken. The program is free of charge. We might even decide to invest directly!"
  },
  perks: {
    title: "What do you get?",
    guidance: {
      title: "Technical guidance",
      description: "Architecture overview, technical support, Superfluid documentation navigation, expert advice"
    },
    refinement: {
      title: "Product refinement",
      description: "We’ll help you tune your product and improve its UX/UI"
    },
    marketing: {
      title: "Marketing support",
      description: "Help to get your product in front of the right audience and ensure an effective distribution"
    },
    strategy: {
      title: "Go to market strategy",
      description: "Strategic advice on how to win the first 1000 users"
    },
    introductions: {
      title: "Introductions",
      description: "We’ll help you connect to both clients and investors with warm targeted introductions"
    }
  },
  faq: {
    title: "Frequently asked questions",
    questions: [
      {
        title: "What’s the goal of Superfluid Reactor?",
        description: "The Superfluid Reactor program is focused on helping high-potential projects building with Superfluid Protocol to successfully launch as independent ventures. We believe that by nurturing a vibrant ecosystem, we can collectively bring Web3 closer to maturity.  ",
      },
      {
        title: "How does this program benefit builders?",
        description: "We support you throughout every stage of your project’s development, from technical assistance to investor intros. Guided by our experience and advice, you can build faster and better — transforming your project from a concept into a full-fledged venture.",
      },
      {
        title: "Who should apply?",
        description: "Anyone who is building an innovative project with Superfluid Protocol.",
      },
      {
        title: "How do I apply?",
        description: `Admission is limited. We accept applications based on a variety of factors, including team quality, product and market potential, and ecosystem impact. If you think your project meets these specifications, please fill out the application form <a href="https://airtable.com/shrSvpcFTdtx2XpNE" target="_blank">here</a>.`,
      },
      {
        title: "Do you take equity? ",
        description: "No, Superfluid Reactor is not an accelerator program and as such does not take any equity positions in projects. This program is focused solely on providing technical and business support to nurture a robust ecosystem.",
      },
      {
        title: "What kind of support do I get from Superfluid?",
        description: "<b>Project Refinement</b> - We help teams refine their project’s technical specifications and unique selling points.\n<b>Technical Guidance</b> - We provide technical support while building the product. Sometimes we also help to improve the code and make the product more secure.\n<b>Investor Pitch Support</b> - We help teams polish their pitch deck and teach them how to present their startup’s investment opportunity to investors.\n<b>Go to market strategies</b> - We provide advice on go-to-market strategies and make connections to potential clients and partners.\n<b>Marketing and promotion</b> - We help your team improve its marketing materials and promote your venture’s launch on our official channels.",
      },
    ]
  }
}
