import React from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";

const IndicatorSvg = styled.svg`
  width: 29px;
  height: 29px;
  flex-shrink: 0;

  @media all ${screenSize.SMM_MAX} {
    width: 18px;
    height: 18px;
  }
`;

const AnimatingLine = styled.line<{ open: boolean }>`
  opacity: ${({ open }) => (open ? 0 : 1)};
  transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1);
`;

const OpenIndicator = ({ open }) => (
  <IndicatorSvg
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1171.000000, -3489.000000)" stroke="#979797">
        <g transform="translate(1171.000000, 3489.000000)">
          <circle
            fill-opacity="0"
            fill="#D8D8D8"
            cx="14.5"
            cy="14.5"
            r="14"
          ></circle>
          <line
            x1="8.5"
            y1="14.5"
            x2="20.5"
            y2="14.5"
            stroke-linecap="square"
          />
          <AnimatingLine
            open={open}
            x1="14.5"
            y1="20.5"
            x2="14.5"
            y2="8.5"
            stroke-linecap="square"
          />
        </g>
      </g>
    </g>
  </IndicatorSvg>
);

export default OpenIndicator;
