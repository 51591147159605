import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInView } from "react-hook-inview";
import Lottie, { Options } from "react-lottie";
import UniverseAnimation from "../../assets/lottie/universe.json";
import ProtocolBackgroundTop from "../../assets/svg/bg-protocol.svg";
import * as S from "./ProtocolSection.style";
import ProtocolSectionPageA from "./ProtocolSectionPageA";
import ProtocolSectionPageB from "./ProtocolSectionPageB";
import ProtocolSectionPageC from "./ProtocolSectionPageC";
import ProtocolSectionPageD from "./ProtocolSectionPageD";
import ProtocolSectionPageE from "./ProtocolSectionPageE";
import ProtocolSectionPageF from "./ProtocolSectionPageF";
import ProtocolSectionPageG from "./ProtocolSectionPageG";
import ProtocolSectionPageH from "./ProtocolSectionPageH";
import ProtocolSectionPageI from "./ProtocolSectionPageI";

const lottieOptions: Options = {
  loop: true,
  autoplay: false,
  animationData: UniverseAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const calcSecondsSince = (val: number) => Math.floor((Date.now() - val) / 1000);

const ProtocolSection: React.FC = () => {
  const [index, setIndex] = useState(0);
  const setFrameCorrection = useRef(false);
  const [lottiePlay, setLottiePlay] = useState(false);
  const [ref, inView] = useInView({
    unobserveOnEnter: true,
    threshold: 0.75,
  });

  const handleLearnMoreClick = (i: number) => setIndex(i);

  useEffect(() => {
    if (!!setFrameCorrection.current) return;
    // Fix for lottie json bad state start.
    setFrameCorrection.current = true;
    setLottiePlay(true);
    setTimeout(() => {
      setLottiePlay(false);
    }, 500);
  }, []);

  useLayoutEffect(() => {
    setLottiePlay(inView);
  }, [inView]);

  const [netFlow, setNetFlow] = useState(1);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [staticBalance, setStaticBalance] = useState(100);
  const [initialAgreement, setInitialAgreement] = useState(20);

  const [fromEntries, setFromEntries] = useState([20]);
  const [toEntries, setToEntries] = useState([-19]);

  const netFlowRate = useMemo(
    () =>
      toEntries.reduce((i, j) => i + j) + fromEntries.reduce((i, j) => i + j),
    [timeElapsed]
  );
  const flowAgreement = useMemo(() => timeElapsed * netFlowRate, [timeElapsed]);
  const balance = useMemo(() => staticBalance + flowAgreement, [flowAgreement]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed(calcSecondsSince(lastUpdate));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [lastUpdate]);

  const updateNetFlow = useCallback(
    (increment: number) => setNetFlow(netFlow + increment),
    [netFlow]
  );

  const updateInitialAgreement = useCallback(
    (val: number) => setInitialAgreement(val),
    [initialAgreement]
  );

  const handleFlowCreationClick = () => {
    setStaticBalance(staticBalance + flowAgreement);
    setLastUpdate(Date.now());
    setTimeElapsed(0);
    setNetFlow(1);

    if (netFlow > 0) {
      setFromEntries([...fromEntries, netFlow]);
    } else {
      setToEntries([...toEntries, netFlow]);
    }
  };
  //

  const defaultProps = {
    onSetIndex: (i: number) => setIndex(i),
    index: index,
    balance: balance,
    data: {
      netFlow,
      timeElapsed,
      initialAgreement,
      staticBalance,
      fromEntries,
      toEntries,
      netFlowRate,
      flowAgreement,
      balance,
    },
  };

  const slides = {
    0: <ProtocolSectionPageA {...defaultProps} />,
    1: <ProtocolSectionPageB {...defaultProps} />,
    2: <ProtocolSectionPageC {...defaultProps} />,
    3: <ProtocolSectionPageD {...defaultProps} />,
    4: (
      <ProtocolSectionPageE
        {...defaultProps}
        onLearnMoreClick={handleLearnMoreClick}
      />
    ),
    5: <ProtocolSectionPageF {...defaultProps} />,
    6: <ProtocolSectionPageH {...defaultProps} />,
    7: (
      <ProtocolSectionPageG
        {...defaultProps}
        handleFlowCreationClick={handleFlowCreationClick}
        updateNetFlow={updateNetFlow}
      />
    ),
    8: (
      <ProtocolSectionPageI
        {...defaultProps}
        updateInitialAgreement={updateInitialAgreement}
      />
    ),
  };

  return (
    <>
      <S.CurveTop src={ProtocolBackgroundTop} ref={ref} id="protocol-section" />
      {slides[index]}
      <S.CurveBottom>
        <div className="container">
          <Lottie
            options={lottieOptions}
            height={"auto"}
            width={"100%"}
            isPaused={!lottiePlay}
          />
        </div>
      </S.CurveBottom>
      {/*<S.CurveBuffer />*/}
    </>
  );
};

export default ProtocolSection;
