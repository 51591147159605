import React from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";

interface Props {
  title: string;
}

const StackedBox: React.FC<Props> = ({ title }) => {
  return (
    <StyledStackedBox>
      <StyledBox offset={0}>
        <p>{title}</p>
      </StyledBox>
      <StyledBox offset={1} />
      <StyledBox offset={2} />
    </StyledStackedBox>
  );
};

const StyledBox = styled.div<{ offset: number }>`
  font-family: "AndaleMono", Courier New, Courier, monospace;
  position: ${({ offset }) => (!offset ? "relative" : "absolute")};
  left: ${({ offset }) => !!offset && `${offset * 6}px`};
  right: ${({ offset }) => !!offset && `-${offset * 6}px`};
  top: ${({ offset }) => !!offset && `${offset * 6}px`};
  bottom: ${({ offset }) => !!offset && `-${offset * 6}px`};
  border: 1px solid
    ${({ theme, offset }) => (!offset ? theme.colors.darkGreen : "#197037")};
  border-radius: 8px;
  z-index: ${({ offset }) => 3 - offset};
  background-color: ${({ theme, offset }) =>
    !offset ? "#1e3b39" : theme.protocolBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-size: 1.25rem;
  }

  @media all ${screenSize.M} {
    p {
      font-size: 1rem;
    }
  }
`;

const StyledStackedBox = styled.div`
  position: relative;
  z-index: 4;
  margin-bottom: 12px;
  margin-right: 12px;
  width: 100%;
`;

export default StackedBox;
