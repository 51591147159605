import styled from "styled-components";
import { screenSize } from "../utils/theme";

export const Modal = styled.div`
  background-color: rgba(18, 20, 30, 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 3;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(16, 1fr);
  align-content: center;
`;

export const Container = styled.div`
  ${({ theme }) => theme.mainGridWithPadding};

  min-height: inherit !important;
  display: flex;
  flex-direction: column;

  @media all ${screenSize.L_MIN} {
    justify-self: center;
    align-content: center;
  }
`;

export const CloseButton = styled.button`
  color: rgb(202, 202, 193);
  font-weight: normal;
  font-size: 0.875rem;
  margin-top: 1rem;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
