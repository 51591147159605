import styled from "styled-components";
import { screenSize } from "../../utils/theme";

const StyledInput = styled.input`
  background: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputColor};
  font-weight: 400;
  transition: background ease-in 0.25s;
  font-size: 1rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-sizing: border-box;

  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholder};
  }

  :hover {
    background: ${({ theme }) => theme.inputBackground};
  }

  :active {
    background: ${({ theme }) => theme.inputBackground};
  }

  @media all ${screenSize.L_MIN} {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
`;

export default StyledInput;
