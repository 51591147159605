import React, { useRef } from "react";
import styled from "styled-components";

import Wrapper from "../Wrapper";
import { home } from "../../assets/strings";
import Footer from "../Footer";
import BrandSection from "../BrandSection";
import BackgroundTop from "../../assets/svg/bg-about-top.svg";
import BackgroundMiddle from "../../assets/svg/bg-about-middle.svg";
import TermsOfUse from "../../assets/strings/termsofuse";
import { screenSize } from "../../utils/theme";
const { meta } = home;

export default () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledWrapper
      title={meta.title}
      description={meta.description}
      ref={wrapperRef}
    >
      <TermsOfUse />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Wrapper)`
  background: url(${BackgroundTop}), url(${BackgroundMiddle});
  background-repeat: no-repeat, no-repeat;
  background-size: 18rem auto, 10rem auto;
  background-position-y: 4rem, 45rem;
  background-position-x: 0, 100%;

  display: flex;
  flex-direction: column;

  @media all ${screenSize.M} {
    background-size: 20rem auto, 10rem auto;
    background-position-y: 0, 40rem;
    background-position-x: 0, 100%;
  }

  @media all ${screenSize.L_MIN} {
    background-size: 30rem auto, 20rem auto;
    background-position-y: -10rem, 20rem;
    background-position-x: 0, 100%;
  }
`;
