import React from "react";
import { track } from "../../utils/user";

export interface Props {
  href?: string;
  target?: string;
  children: React.ReactNode;
  className?: string;
  id?: string;
  text?: string;
  key?: string;
}

const Link: React.FC<Props> = (props) => {
  const { href, target, children, id, className, key } = props;
  const text = typeof children === "string" ? children : props.text;

  const handleClick = (e: any) => {
    e.stopPropagation();
    return track("Link Clicked", {
      linkText: text,
      url: href,
    });
  };

  return (
    <a
      className={className}
      href={href}
      target={target}
      onClick={handleClick}
      id={id}
      key={key}
    >
      {children}
    </a>
  );
};

export default Link;
