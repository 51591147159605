// use this file to generate a UUID for the user, attach it to window as a property, and pass it to segment if it's active.
import { v4 as uuidv4 } from "uuid";
import hmacSHA256 from "crypto-js/hmac-sha256";

const CryptoJS = require("crypto-js");

export function user() {
  var cookieId = getCookie("uuid");
  var localId = JSON.parse(localStorage.getItem("ajs_user_id"));
  var windowId = window.UUID;
  var id;
  if (isId(localId)) {
    id = localId;
  } else if (isId(windowId)) {
    id = windowId;
  } else if (isId(cookieId)) {
    id = cookieId;
  } else id = uuidv4();
  window.UUID = id;
  setCookie("uuid", id);
  return id;
}
// add here all the analytics stuff, wrapped nicely

export function identify(traits) {
  try {
    const userId = user();
    const hashInHex = CryptoJS.enc.Hex.stringify(
      hmacSHA256(userId, process.env.REACT_APP_INTERCOM_SECRET_KEY || "")
    );
    window.analytics.identify(userId, {
      ...traits,
      user_hash: hashInHex,
    });
  } catch (e) {
    console.log(e);
  }
}

export function track(event, data) {
  const eventName = "Website - " + event;
  try {
    window.analytics.track(eventName, { ...data });
  } catch (e) {
    console.log(e);
  }
}

function isId(id) {
  return typeof id === "string" && id.length > 0 && id != "null" && id != "";
}

function setCookie(cname, cvalue) {
  if (window.location.hostname.includes("superfluid.dev")) {
    document.cookie = cname + "=" + cvalue + ";domain=superfluid.dev";
  } else if (window.location.hostname.includes("superfluid.finance")) {
    document.cookie = cname + "=" + cvalue + ";domain=superfluid.finance";
  } else {
    document.cookie = cname + "=" + cvalue + ";domain=localhost";
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
