import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const TermsOfUse = () => {
  const LocalWrapper = styled.div`
    align-items: center;
    margin-bottom: 4.5rem;
    padding: 4.5rem !important;
  `;
  const BoldP = styled.p`
    * {
      font-weight: 500;
    }
  `;
  const H1 = styled.h1`
    text-align: center;
  `;
  const p = styled.p`
    text-align: left;
  `;
  const H3 = styled.h3`
    font-weight: 500;
    margin-top: 2em;
  `;

  return (
    <LocalWrapper>
      <div class="c18">
        <p class="c15">
          <H1>
            SUPERFLUID TERMS OF USE <br />
          </H1>
          <p>
            Version: 0.3
            <br />
            Latest Reviewed on: 08 November 2021
          </p>
        </p>
        <p class="c12">
          <span class="c1" style={{ "text-align": "left" }}>
            These terms and conditions (&ldquo;Terms&rdquo;) are between
            Superfluid Finance Ltd (&ldquo;Superfluid&rdquo;, &ldquo;we&rdquo;,
            &ldquo;us&rdquo; or &ldquo;our&rdquo;) and you (together with
            Superfluid, the &ldquo;parties&rdquo; and each a
            &ldquo;party&rdquo;) and relate to.
          </span>
        </p>
        <BoldP>
          <span class="c0">
            Please read these terms carefully. These terms govern your access to
            and use of the{" "}
          </span>
          <span class="c10">
            <a class="c9" href="https://www.superfluid.finance">
              https://www.superfluid.finance//
            </a>
          </span>
          <span class="c1">&nbsp;</span>
          <span class="c0">
            site (the &ldquo;Site&rdquo;), Superfluid&rsquo;s App (as defined
            below). By using the Site and / or the App (collectively the
            &ldquo;Service&rdquo;), you agree to these terms.{" "}
          </span>
        </BoldP>
        <p class="c12">
          <span class="c1">
            The Service is provided on a free of charge, &lsquo;as is&rsquo; and
            &lsquo;as available&rsquo; basis and your use of the Service is at
            your own risk. Your attention is drawn in particular to the risks
            and responsibilities that you assume in relation to the Service as
            described in clause 4, and the exclusions of our liability set out
            in clause 7 below.
          </span>
        </p>
        <p class="c12">
          <span class="c1">
            Your use of the Site and the Service is also governed by{" "}
          </span>
          <span class="c10">
            <a
              class="c9"
              href="https://www.iubenda.com/privacy-policy/34415583/legal"
            >
              Superfluid&rsquo;s Privacy Policy
            </a>
          </span>
          <span class="c10">&nbsp;and </span>
          <span class="c10">
            <a
              class="c9"
              href="https://www.iubenda.com/privacy-policy/gdpr/34415583/cookie-policy"
            >
              Superfluid&rsquo;s Cookie Policy
            </a>
          </span>
          <span class="c1">. </span>
        </p>
        <p class="c12">
          <span class="c1">
            We may make changes to these Terms, the privacy policy or the cookie
            policy from time to time. Every time you wish to use the Service,
            please check these terms to ensure you understand the terms that
            apply at that time. Your continued use of the Site and Services
            constitute acceptance of these changes.
          </span>
        </p>
        <ol start="1">
          <li>
            <H3>DEFINITIONS AND INTERPRETATION </H3>
          </li>
          <ol class="c5 lst-kix_list_3-1 start" start="1">
            <li class="c2 c6">
              <span class="c1">
                In these Terms, the following terms have the following meanings:
              </span>
            </li>
          </ol>
          <p class="c2 c8">
            <span class="c3">&ldquo;App&rdquo; </span>
            <span class="c1">
              means any Superfluid decentralised finance application or
              interface, which may be made available via an internet browser on
              a &ldquo;software as a service&rdquo; basis;
            </span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;Service&rdquo; </span>
            <span class="c1">
              means access to and use of the Site and/or the functionalities of
              the App (as applicable);
            </span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;Site&rdquo; means </span>
            <span class="c14">
              <a class="c9" href="https://www.superfluid.finance">
                https://www.superfluid.finance
              </a>
            </span>
            <span class="c3">;</span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;Smart Contract&rdquo; </span>
            <span class="c1">
              means a self-executing software program with the terms of an
              agreement between two or more parties being directly written into
              lines of code and executed by a blockchain network or private
              network. The code controls its execution autonomously, and
              transactions are usually publicly trackable and irreversible by
              nature;
            </span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;Token&rdquo;</span>
            <span class="c1">
              &nbsp;means any digital token or asset (which may be referred to
              as a &lsquo;cryptocurrency&rsquo;) which is made available for
              transfer, wrapping, or exchange using the App;
            </span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;Transaction&rdquo; </span>
            <span class="c1">
              means the transfer of Tokens or data through the use of Smart
              Contracts or other means; and
            </span>
          </p>
          <p class="c2 c8">
            <span class="c3">&ldquo;User&rdquo; </span>
            <span class="c1">means a user of the Service.</span>
          </p>
          <ol class="c5 lst-kix_list_3-1" start="2">
            <li class="c2 c6">
              <span class="c1">
                The headings in these Terms are for convenience only and do not
                affect the interpretation of these Terms. Words expressed in the
                singular include the plural and vice versa. The words
                &quot;other&quot;, &quot;including&quot; and &quot;in
                particular&quot; do not limit the generality of any preceding
                words.
              </span>
            </li>
          </ol>
          <li>
            <H3>USE OF THE SERVICE </H3>
            <ol class="c5 lst-kix_list_3-1 start" start="1">
              <li class="c2 c6">
                <span class="c1">
                  Access to the Service is provided on a non-exclusive, free of
                  charge, &ldquo;as is&rdquo; and &ldquo;as available&rdquo;
                  basis only.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  The App is currently in beta testing. Accordingly, you should
                  only use the Service for evaluation purposes in connection
                  with test Transactions having no value. Any use of the Service
                  is entirely at your own risk.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  Superfluid does not guarantee that the Service will always be
                  available or that use will be uninterrupted or error free.{" "}
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  We may terminate, limit or restrict access to the Site and the
                  Service at any time without notice and for any reason,
                  including limiting or restricting access by geography. In no
                  event do we have the ability to access or suspend your access
                  to your own Wallet.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  The App may involve accessing markets and interfaces allowing
                  for Transactions of Tokens. To use the Service, you must
                  already have a digital wallet that can store and transfer
                  Tokens that are supported by the App (&ldquo;Wallet&rdquo;).
                  As part of the Service, you may be able to monitor real-time
                  movements of Tokens, track a portfolio of Tokens and access
                  certain supported decentralized protocols and decentralized
                  applications through supported web browsers.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  Where a Wallet is created, a cryptographic private and public
                  key pair is generated. The private and public key pair
                  together evidence ownership/possession of a specific amount of
                  supported Tokens in that Wallet which enables you to send and
                  receive Tokens through a Blockchain network. The public key
                  may be visible to all participants in such Blockchain network.
                  The private key must be kept secret and used to transact the
                  Tokens represented by the corresponding public key. In some
                  instances, depending on what other services you have used to
                  create a Wallet, you may receive a pin code, create a
                  password, or establish another method of accessing your
                  private key as a security or convenience measure. In those
                  cases, your authentication method may function similarly to
                  your private key in that it allows you and other in possession
                  of such information to potentially transfer Tokens from your
                  Wallet. Superfluid will never have access to Tokens in your
                  Wallet, we will not store your private key or similar methods
                  of accessing your Wallet, and we will never request this
                  information.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  When you request to make a Transaction via the App, you will
                  be required to initiate a transfer from your Wallet&rsquo;s
                  interface (a &ldquo;Transfer Initiation&rdquo;). Superfluid is
                  entitled to rely on the Transfer Initiation and has no duty to
                  inquire into or investigate the validity or accuracy of any
                  Transfer Initiation. You will be responsible for keeping your
                  hardware devices, including your phone, secure and for any
                  activity associated with such devices and your Wallet when
                  using the Service. Superfluid will not be responsible if
                  someone else accesses your devices and authorizes a
                  transaction upon receipt of a valid Transfer Initiation.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  We may make changes to the Service at any time to time without
                  notice, including, without limitation, the right to add or
                  remove Tokens from the Service. The inclusion or removal of
                  any particular Token does not constitute any form of
                  endorsement of that Token by Superfluid.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <H3>RESTRICTIONS ON USE</H3>
            <ol class="c5 lst-kix_list_3-1 start" start="1">
              <li class="c2 c6">
                <span class="c1">
                  You accept and agree as follows in relation to your use of the
                  Service.
                </span>
              </li>
              <ol type="a" start="1">
                <li>
                  <span class="c1">
                    You will not use the Service for any purpose that does not
                    comply with applicable law or infringes the rights
                    (including intellectual property rights) of Superfluid or
                    any other user.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not use the Service for commercial purposes (other
                    than to facilitate Transactions with your customers,
                    partners, employees, contractors or suppliers) without our
                    express written permission.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Subject to section 296A of the Copyright, Designs and
                    Patents Act 1988, you will not decompile, disassemble,
                    modify, or reverse engineer any code in respect of the Site
                    or the App that you are provided access to, will not attempt
                    to access any code that you are not provided access to, and
                    you will not use the Service to build a competitive product,
                    service or function.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not engage in any conduct that restricts or
                    inhibits anyone&rsquo;s use of the Service (including
                    anything which affects the security of any other
                    user&rsquo;s Wallet).
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not use the Service in such a way which is harmful,
                    fraudulent, deceptive, threatening, harassing, defamatory,
                    obscene, or otherwise &nbsp;objectionable.{" "}
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not to attempt to gain unauthorised access to,
                    interfere with, or damage the proper working of the Site or
                    the App.{" "}
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not access, or to attempt to access, another
                    user&rsquo;s Wallet, private key or other security
                    information on any third party service that provide access
                    to such user&rsquo;s wallet or private key on the Service.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not compromise, or to attempt to compromise, the
                    security of any computer network, or to crack any passwords
                    or encryption codes.{" "}
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not use the Service in any manner which could
                    disable, damage, or impair the Site, the App or interfere
                    with any other party&rsquo;s use of the Service.{" "}
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You will not knowingly introducing viruses, trojans, worms,
                    logic bombs or other material that is malicious or
                    technologically harmful. You must not attempt to gain
                    unauthorised access to the Site, the server on which the
                    Site is stored or any server, computer or database connected
                    to the Site. You must not attack the Site via a
                    denial-of-service attack or a distributed denial-of service
                    attack. By breaching this provision, you would commit a
                    criminal offence under the Computer Misuse Act 1990.
                    Superfluid will report any such breach to the relevant law
                    enforcement authorities and we will co-operate with those
                    authorities by disclosing your identity to them. In the
                    event of such a breach, your right to use the Site will
                    cease immediately.
                  </span>
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <H3>YOUR RESPONSIBLITIES AND ASSUMPTION OF RISK</H3>
            <ol start="1">
              <li class="c2 c6">
                <span class="c1">You accept and agree as follows. </span>
              </li>
              <ol type="a" start="1">
                <li class="c2 c4">
                  <span class="c1">
                    Superfluid is not regulated by the UK Financial Conduct
                    Authority or any other regulatory body and is not therefore
                    subject to their rules. The Service does not constitute
                    regulated financial services and does not provide or offer
                    insurance cover to Users. No assets are covered by the UK
                    Financial Services Compensation Scheme or any other
                    compensation scheme. Therefore, there is no regulatory
                    protection of any kind available to you or other Users in
                    connection with your use of the Services in any scenario.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Any Smart Contract that you and any third party interact
                    with is a private arrangement between you and that third
                    party. Superfluid is not a party to any Smart Contract, does
                    not act as an intermediary in relation to any Smart Contract
                    or any Transaction, has no responsibility for verifying the
                    identity of any party to any Smart Contract or Transaction
                    and has no responsibility in relation to any disputes
                    relating to any Smart Contract or Transaction.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You should not use the Service if you do not have prior
                    understanding of Blockchain technology and the risks and
                    applicable compliance requirements of its use.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    As owner of the Tokens on the platform, you are solely
                    responsible for all activities and Transactions which occur
                    in connection with your use of the Service and your Wallet.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You are solely responsible for ensuring that your use of the
                    Service, and the entering into of any Smart Contracts or
                    Transactions, does not breach any applicable laws that apply
                    to you.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    You are solely responsible for informing yourself about the
                    operation of the relevant software protocols that underpin
                    any Tokens that you choose to use in Transactions.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Tokens may be ascribed value by third parties (either by way
                    of a cash valuation, or a valuation expressed in other
                    Tokens). Superfluid has no control over any cryptocurrencies
                    (including any Stablecoin) and is not responsible for any
                    fluctuations in value, stability or liquidity of any Tokens.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Delays in Transaction execution may arise from the inherent
                    functioning of the Blockchain network to which your
                    Transaction will be processed through. Superfluid has no
                    responsibility for any delay between the initial processing
                    and execution of any Transaction, or for any error in or
                    failure of any Transaction, no matter how such delays,
                    errors or failed transactions may arise.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Superfluid will have no ability to access, use or exert any
                    control over the Tokens that are locked within a Smart
                    Contract and the relevant owner of such Tokens will retain
                    control of the Tokens through its private key. If you lose
                    your private key, or control of the private key, you may not
                    be able to recover or use your Tokens.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    We have no control over any User&rsquo;s Transaction, and we
                    cannot cancel, undo or reverse any Transaction initiated by
                    you. We shall have no liability for any transactions which
                    you may have executed (or tried to execute), even by
                    mistake. &nbsp;
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Superfluid gives no guarantee as to the security of any
                    Blockchain technology and is not liable for any hacks,
                    double spending, stolen Tokens, or any other attacks on, or
                    failures of, the Blockchain technology.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Superfluid does not have access to your Wallet or private
                    key and cannot initiate a transfer of, or otherwise access,
                    your Tokens. Superfluid is not acting as your broker,
                    intermediary, agent, advisor, or custodian, and we do not
                    have a fiduciary relationship or obligation to you regarding
                    any other decisions or activities that you effect when using
                    your Wallet or the Service.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    The content and materials available when using the Service
                    are for informational purposes only and do not constitute
                    any form of advice or recommendation by us, should not be
                    regarded as an offer, solicitation, invitation or
                    recommendation to buy or sell investments, securities or any
                    other financial services and is not intended to be relied
                    upon by you in making any specific investment or other
                    decisions. We recommend you seek independent advice from a
                    financial or investment advisor before making any such
                    decision.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Where the Service contains links to other sites and
                    resources provided by third parties, these links are
                    provided for your information only. Such links should not be
                    interpreted as approval by Superfluid of those linked
                    websites or information you may obtain from them. We have no
                    control over the contents of those sites or resources.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Internet transmissions are never fully private or secure.
                    You understand that any information you send whilst using
                    the Service may be read or intercepted by others, regardless
                    of whether the transmission is encrypted. Superfluid shall
                    not be responsible for any interception you may experience
                    when using the Service, however caused.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    Superfluid does not guarantee that the Service will be
                    secure or free from viruses. You are responsible for
                    configuring your information technology and systems to
                    access the Site and the Service and you should use your own
                    up to date virus protection software.
                  </span>
                </li>
                <li class="c2 c4">
                  <span class="c1">
                    The Service relies on the proper functioning of services or
                    products provided by third parties, including, but not
                    limited to protocols, blockchain technology, smart
                    contracts, blockchain networks, cloud service providers and
                    third party applications or tools from time to time (&ldquo;
                  </span>
                  <span class="c0">Third Party Services</span>
                  <span class="c1">
                    &rdquo;). We do not control, endorse or adopt any Third
                    Party Services and have no responsibility for any Third
                    Party Services. We cannot and will not monitor, verify,
                    censor or edit the content of any Third Party Services. We
                    are not responsible for any loss as a result of the use of
                    Third Party Services and such use is beyond our control and
                    at your own risk. You are solely responsible for making
                    yourself aware of any protocol &lsquo;forks&rsquo;, update,
                    failure or hack and taking appropriate action to safeguard
                    yourself from their effects, if they occur.
                  </span>
                </li>
              </ol>
            </ol>
          </li>
          <li>
            <H3>CONFIDENTIALITY </H3>
            <p>
              <span>
                You may be given access to Superfluid&rsquo;s confidential
                information in connection with the Services. Where this happens,
                y
              </span>
              <a></a>
              <span>
                ou shall hold Superfluid&rsquo;s confidential information in
                confidence and, for so long as it remains confidential (other
                than as a result of your breach of these Terms) not make our
                confidential information available to any third party, or use
                our confidential information for any purpose other than the use
                of the Services. This clause
              </span>
              <a id="id.tyjcwt"></a>
              <span class="c1">
                does not apply to disclosure of confidential information to the
                extent the disclosure is required by law, by any governmental or
                other regulatory authority or by a court or other authority of
                competent jurisdiction.
              </span>
            </p>
          </li>
          <li>
            <H3>INTELLECTUAL PROPERTY RIGHTS</H3>
            <ol class="c5 lst-kix_list_3-1 start" start="1">
              <li class="c2 c6">
                <span class="c1">
                  All intellectual property rights in or arising out of or in
                  connection with the Site, the App and the Service, and all
                  trade marks of Superfluid shall remain the property of
                  Superfluid.
                </span>
              </li>
              <li class="c2 c6" id="h.3dy6vkm">
                <span class="c1">
                  Subject to your compliance with these Terms, Superfluid grants
                  you a limited, non-transferable, non-exclusive licence to use
                  the Service.{" "}
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  You may not modify, publish, transmit, participate in the
                  transfer or sale of, reproduce (except as necessary to receive
                  the Service), or create derivative works based on the Site or
                  the App.{" "}
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  You acknowledge and agree that the Service may use,
                  incorporate or link to certain open-source components
                  available on open source licences and that your use of the
                  Service is subject to, and you will comply with any,
                  applicable open-source licenses that govern any such
                  open-source components. Nothing in these Terms will be
                  construed to limit any rights granted under such open sources
                  licences with respect to the components specifically covered
                  by such licences.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  Any data or other content you upload to or submit using the
                  Service will be considered non-confidential. You retain all of
                  your ownership rights in your content, but when you upload or
                  post content to our site, you grant us a perpetual, worldwide,
                  royalty-free, transferrable, non-exclusive licence to use,
                  copy, distribute, prepare derivative works of, display, and
                  publish that data and content in any manner (including to
                  promote the Site and Service).
                </span>
              </li>
            </ol>
          </li>
          <li>
            <H3>OUR LIABILITY</H3>
            <ol start="1">
              <li class="c2 c6">
                <span class="c1">
                  These Terms are (to the fullest extent permitted by law) in
                  lieu of and to the exclusion of any other warranty, condition,
                  term or undertaking of any kind (including those implied by
                  law), statutory or otherwise, relating to the Service or
                  anything to be done under or in connection with these Terms.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  Subject to clause 7.4, in no event will we be liable for any
                  claims, penalties, loss, damages, expenses or harm arising out
                  of or in connection with your use of the Service or in
                  relation to any Transactions you execute (or attempt to
                  execute).
                </span>
              </li>
              <li class="c2 c6" id="h.4d34og8">
                <span class="c1">
                  Subject to clause 7.4, You agree to indemnify and reimburse
                  Superfluid for all costs, charges, damages or losses which we
                  may suffer in relation to your use of the Service, including
                  in relation to (a) any content which you may upload to the App
                  or any interaction you may have with any other user of the
                  App; and (b) any tax implication for which Superfluid may
                  become liable as a result of your use of the Service.
                </span>
              </li>
              <li class="c2 c6" id="h.2s8eyo1">
                <span class="c1">
                  If you are not a consumer (i.e. using the Service in the
                  course of business, trade or profession), subject to clause
                  7.4, Superfluid shall not be liable to you, whether in
                  contract, in tort (including negligence), for breach of
                  statutory duty, or otherwise, arising under or in connection
                  with these Terms for: (a) loss of profit; (b) loss of revenue;
                  (c) loss of anticipated savings; (d) loss, destruction or
                  corruption of data; (e) loss of contract, business or
                  opportunity; (f) loss of goodwill; and (g) wasted expenditure.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  Nothing in these Terms limits or excludes Superfluid&rsquo;s
                  liability: (i) to the extent that it cannot be legally limited
                  or excluded by law; (ii) for death or personal injury arising
                  out of its negligence or that of its personnel; and (iii) for
                  losses suffered by you arising out of our fraud or fraudulent
                  statement.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <H3>DATA PROTECTION</H3>
            <p class="c2 c8">
              <span class="c1">Please see </span>
              <span class="c10">
                <a
                  class="c9"
                  href="https://www.iubenda.com/privacy-policy/34415583/legal"
                >
                  Superfluid&rsquo;s Privacy Policy
                </a>
              </span>
              <span class="c1">
                &nbsp;for information on how Superfluid processes and stores
                Users&rsquo; personal data.
              </span>
            </p>
          </li>
          <li>
            <H3>CUSTOMER SUPPORT</H3>
            <p class="c2 c8">
              <span class="c1">
                For customer or technical support, please refer to the{" "}
              </span>
              <span class="c10">
                <a class="c9" href="http://help.superfluid.finance">
                  Superfluid Supporting Documentation
                </a>
              </span>
              <span class="c1">
                &nbsp;for guidance. For all other queries (including any
                questions about these Terms), please use our Support Chat
                function on the Site. We cannot guarantee immediate responses,
                especially during periods of high volume.
              </span>
            </p>
          </li>
          <li class="c2 c6 c7">
            <H3>GENERAL</H3>
            <ol class="c5 lst-kix_list_3-1 start" start="1">
              <li class="c2 c6">
                <span class="c1">
                  <b>Sanctions</b> – You represent and warrant on an ongoing basis at all
                  relevant times that you are not, nor is any individual with access to
                  your User account or any of your affiliates, subject to any financial,
                  economic or trade sanctions or embargoes or otherwise designated on any
                  list of prohibited or restricted parties or territories, including any
                  such lists maintained from time to time by the United Nations Security
                  Council, the UK Government, the US Government, the European Union or
                  its member states or any member states of the European Free Trade
                  Association, or other applicable government authority, including but not 
                  limited to: Belarus, Burma (Myanmar), Burundi, Central African Republic, 
                  Congo, Côte d'Ivoire, Cuba, Iran, Iraq, Lebanon, Liberia, Libya, Mali, 
                  Nicaragua, North Korea, Somalia, South Sudan, Sudan, Syria, Crimea 
                  (Ukraine/Russia), Venezuela, Yemen, Zimbabwe. In the eventthat, 
                  at any relevant time, the warranty outlined in the preceding
                  sentence ceases to be true, you shall notify us immediately.
                  We reserve the right to restrict access to the Service in specific
                  countries or territories (for example through the use of
                  ‘geo-fencing’ technology).
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Waivers</b> - No failure or delay by a party to exercise any right or remedy
                  provided under these Terms or by law shall constitute a waiver
                  of that or any other right or remedy, nor shall it prevent or
                  restrict the further exercise of that or any other right or
                  remedy. No single or partial exercise of such right or remedy
                  shall prevent or restrict the further exercise of that or any
                  other right or remedy.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Third Party Rights</b> – The Terms do not confer any rights on any person other than
                  the parties to the Terms (and, where applicable, their
                  successors and permitted transferees).
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Use of Subcontractors</b> – We may perform any of our obligations, and exercise any of the
                  rights granted to us under the Terms, through a third-party.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Assignment</b> - We may transfer our rights and obligations under the Terms to
                  any third party. You may only transfer your rights or
                  obligations under the Terms to another person if we agree in
                  writing.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Entire Agreement</b> - The Terms constitute the entire agreement between the parties
                  and supersede and extinguish all previous agreements,
                  promises, assurances, warranties, representations and
                  understandings between them, whether written or oral, relating
                  to its subject matter.
                </span>
              </li>
              <li class="c2 c6">
                <span class="c1">
                  <b>Governing Law and Jurisdiction</b> - The Terms are intended to be legally binding. The Terms and
                  any dispute or claim arising out of or in connection with them
                  or their subject matter or formation (including
                  non-contractual disputes or claims) shall be governed by, and
                  construed in accordance with, English law and subject to the
                  exclusive jurisdiction of the English courts.
                </span>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </LocalWrapper>
  );
};

export default TermsOfUse;
