import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Wrapper = styled.div`
  margin-top: -2px;
  grid-auto-flow: row;
  grid-template-columns: repeat(16, 1fr);
  grid-column: 1/17;
  background: ${({ theme }) => theme.protocolBackground};
  display: grid;
  box-sizing: border-box;
  margin-bottom: -2em;
  z-index: 10;

  h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 2.4rem;
    letter-spacing: -2px;
    line-height: 3.5rem;
    font-weight: 400;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    padding: 0 1rem;
    line-height: 1.6rem;
    font-size: 1.2em;

    span {
      background: ${({ theme }) => theme.colors.green};
      padding: 0 0.5rem;
      color: ${({ theme }) => theme.colors.dark};
      border-radius: 0.25rem;
      display: inline-block;
    }
  }
`;

export const InnerWrapper = styled.div`
  ${({ theme }) => theme.mainGridWithPadding};

  flex-direction: column;
  width: 90%;
  grid-column: 1/17;
  padding: 0 2rem;
  box-sizing: border-box;
  height: 100%;
  min-height: 38rem;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-column: 3/15;
  margin-top: 2rem;
  /*padding-bottom: 48px;*/

  @media all ${screenSize.L_MIN} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }

  button {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const AltFooter = styled(Footer)`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DeepDive = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 2rem;

  @media all ${screenSize.L_MIN} {
    align-items: flex-start;

    p {
      font-size: 1em;
    }
  }

  p {
    text-align: right;
    padding: 0;
    margin: 0;
  }

  p > span {
    background: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    border-radius: none;
    display: block;

    @media all ${screenSize.L_MIN} {
      display: inline;
    }
  }
`;

export const AccessoryBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem 0;

  button: {
    align-self: flex-end;
  }

  @media all ${screenSize.L_MIN} {
    flex-direction: column-reverse;
    align-items: flex-end;

    button {
      font-size: 1.2em;
    }
  }
`;

export const NextButton = styled.button`
  align-self: flex-end;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
