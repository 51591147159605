import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Wrapper = styled.div`
  border-radius: 4px;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const VerticalSpacer = styled.div`
  height: 4.5rem;

  @media all ${screenSize.L_MIN} {
    height: 4rem;
  }
`;

export const HorizontalSpacer = styled.div`
  width: 4.5rem;
`;

export const Quote = styled.p`
  font-size: 1.5rem;
  color: white;
  line-height: 2rem !important;
  font-weight: normal;
`;

export const DesktopWrapper = styled.div`
  @media all ${screenSize.L_MIN} {
    flex-direction: column;
    margin-left: 4rem;
  }
`;
