import React, { ReactNode } from "react";
import { home } from "../../assets/strings";
import SmallCubeIcon from "../../assets/svg/icon-cube-2.svg";
import * as S from "./ProtocolSectionBase.style";
import ProtocolSectionIndicator from "./ProtocolSectionIndicator";

const { protocolSection: root } = home;

enum State {
  DEFAULT,
  INFO,
  DEMO,
}

interface Props {
  index: number;
  onSetIndex: (index: number) => void;
  children?: ReactNode;
}

const SLIDE_LIMIT = Object.keys(root.slides).length - 4; // 4 extras

const ProtocolSectionBase: React.FC<Props> = ({
  index,
  onSetIndex,
  children,
}) => {
  const state = (() => {
    if (index < 5) {
      return State.DEFAULT;
    } else if (index === 5 || index === 6) {
      return State.INFO;
    } else {
      return State.DEMO;
    }
  })();

  const getDemoPrevious = () => {
    if (index === 7) return 5;
    if (index === 8) return 6;
    return 4;
  };

  const getDemoNext = () => {
    if (index === 5) return 7;
    if (index === 6) return 8;
    return 4;
  };

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        {children}
        {state === State.DEFAULT && (
          <S.Footer>
            <S.AccessoryBox>
              <ProtocolSectionIndicator
                index={index}
                count={SLIDE_LIMIT}
                onClick={onSetIndex}
              />
              {SLIDE_LIMIT - 1 !== index && (
                <S.NextButton onClick={() => onSetIndex(index + 1)}>
                  {root.next}
                </S.NextButton>
              )}
            </S.AccessoryBox>
            <S.DeepDive>
              <img src={SmallCubeIcon} />
              <p dangerouslySetInnerHTML={{ __html: root.footer }} />
            </S.DeepDive>
          </S.Footer>
        )}
        {state === State.INFO && (
          <S.AltFooter>
            <S.NextButton onClick={() => onSetIndex(getDemoNext())}>
              {root.tryDemo}
            </S.NextButton>
            <S.NextButton onClick={() => onSetIndex(4)}>
              {root.previous}
            </S.NextButton>
          </S.AltFooter>
        )}
        {state === State.DEMO && (
          <S.AltFooter>
            <S.NextButton onClick={() => onSetIndex(4)}>
              {root.backToOverview}
            </S.NextButton>
            <S.NextButton onClick={() => onSetIndex(getDemoPrevious())}>
              {root.previous}
            </S.NextButton>
          </S.AltFooter>
        )}
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default ProtocolSectionBase;
