import React from "react";
import { home } from "../../assets/strings";
import MultiBoxIcon from "../../assets/svg/icon-multiple-box.svg";
import OutBoxIcon from "../../assets/svg/icon-out-box.svg";
import IconBox from "../../components/IconBox";
import InputBox from "../../components/InputBox";
import QuoteBox from "../../components/QuoteBox";
import { ProtocolSectionProps as Props } from "../../types";
import ProtocolSectionBase from "./ProtocolSectionBase";
import * as S from "./ProtocolSectionPageH.style";

const { protocolSection: root } = home;

const ProtocolSectionPageH: React.FC<Props> = ({ onSetIndex, index, data }) => {
  const slide = root.slides["H"];
  const { initialAgreement } = data;

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Wrapper>
        <InputBox
          title={slide.idaBoxTitle}
          subtitle={slide.idaBoxSubtitle}
          unit={slide.idaBoxUnit}
          icon={true}
          value={`${Math.ceil(initialAgreement)}`}
          editable={false}
        />
        <S.VerticalSpacer />
        <S.DesktopWrapper>
          <QuoteBox>
            <S.Quote>{slide.quoteText}</S.Quote>
          </QuoteBox>
          <S.VerticalSpacer />
          <IconBox image={OutBoxIcon}>
            <p>{slide.oneWayText}</p>
          </IconBox>
          <S.VerticalSpacer />
          <IconBox image={MultiBoxIcon}>
            <p>
              {slide.depositTextA} <span>{slide.depositTextB}</span>.
            </p>
          </IconBox>
        </S.DesktopWrapper>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageH;
