import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const VerticalSpacer = styled.div<{ size?: number; isLine?: boolean }>`
  height: ${({ size }) => (!size ? "4.5rem" : `${size}rem`)};

  ${({ isLine, theme, size }) =>
    isLine && {
      width: `0.75rem`,
      borderLeftWidth: 2,
      borderLeftStyle: "solid",
      borderRightWidth: 2,
      borderRightStyle: "solid",
      borderLeftColor: `${theme.colors.green}`,
      borderRightColor: `${theme.colors.green}`,
      marginTop: `calc(-1rem - 2px)`,
      height: `${size * 2}rem`,
    }}

  @media all ${screenSize.L_MIN} {
    display: none;
  }
`;

export default VerticalSpacer;
