import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Title = styled.h3`
  grid-column: 3/15;
`;

export const Wrapper = styled.div`
  grid-column: 3/15;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth};

  p {
    margin: 0;
  }

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 4.5rem;

  @media all ${screenSize.L_MIN} {
    margin-bottom: 2.5rem;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const VerticalSpacer = styled.div`
  height: 4.5rem;

  @media all ${screenSize.L_MIN} {
    height: 4rem;
  }
`;

export const HorizontalSpacer = styled.div`
  width: 4.5rem;
`;
