import React, { useEffect, useLayoutEffect, useState } from "react";
import * as S from "./Header.style";
import Logo from "../../assets/svg/logo.svg";
import Button from "../../components/elements/Button";
import Link from "../../components/elements/Link";

import { home } from "../../assets/strings";
import withLocation, { Props as LocationProps } from "../../utils/withLocation";
import { track } from "../../utils/user";
import useWindowScroll from "react-use/lib/useWindowScroll";
import useMeasure, { UseMeasureRef } from "react-use/lib/useMeasure";

import { ModalType, useModalContext } from "../../context/modalContext";

interface Props extends LocationProps {
  isMobile: boolean;
}

const { getStartedSection: root } = home;

const GetStartedSection: React.FC<Props> = ({ isMobile, search }) => {
  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [showedModal, setShowedModal] = useState(false);

  const { y } = useWindowScroll();
  const [ref, { bottom }] = useMeasure();

  const [_, setModalVisibility] = useModalContext();

  useEffect(() => {
    if (showedModal) return;

    const { signup } = search;
    if (signup == "invest")
      setModalVisibility({ type: ModalType.InvestorCapture });
    else if (signup == "merchant")
      setModalVisibility({ type: ModalType.MerchantEmailCapture });
    setShowedModal(true);
  });

  /*const isEmailValid = useMemo(() => !!email && validateEmail(email), [email]);

  const handleEmailClick = () => {
    track("Email Input Selected", { header: true });
  };*/

  const handleSubscribeClick = () => {
    //if (isEmailValid) {
    setModalVisibility({ type: ModalType.GetStarted });
    /*post({ email });
      identify({ email });
      track("Email Submitted", { email, header: true });*/
    track("Clicked Get Started");
    setError(false);
    // } else {
    //   setError(true);
    //   setTimeout(() => setError(false), 5000);
    // }
  };

  const handleRowMouseEnter = (i: number) =>
    setActiveRow(i === 0 ? 0 : undefined);

  const handleRowMouseLeave = (i: number) => setActiveRow(i);

  // const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   !!e.target.value && setEmail(e.target.value);

  return (
    <>
      <S.Header>
        <Link href="/"><S.Logo src={Logo} alt={root.logoAltTitle} /></Link>
        <S.DesktopEmailCapture>
          <div className={"w"}>
            <S.InputContainer>
              {/*<Input
                placeholder={root.inputPlaceholder}
                onChange={handleEmailChange}
                onClick={handleEmailClick}
              />*/}
              <Button
                title={root.ctaButtonTitle}
                onClick={handleSubscribeClick}
              />
            </S.InputContainer>
            {/*
            <S.Message error={error}>
              {error ? root.inputError : root.inputMessage}
            </S.Message>*/}
          </div>
        </S.DesktopEmailCapture>
      </S.Header>
    </>
  );
};

export default withLocation(GetStartedSection);
