import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const BoxContainer = styled.div`
  border-radius: 4px;
  border: 2px solid rgb(27, 132, 71, 0.5);
  border-style: dashed;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const VerticalArrow = styled.img`
  height: 2rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    margin-top: 0.5rem;
    text-align: right;
    justify-self: flex-end;

    a {
      text-decoration: underline;
    }
  }
`;

export const PlusImage = styled.img`
  width: 1rem;
  height: auto;
  margin: 1rem 0;

  @media all ${screenSize.L_MIN} {
    margin: 0 1rem;
  }
`;
