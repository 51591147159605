import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import FXIcon from "../assets/svg/icon-fx.svg";
import InputBox from "./InputBox";

interface Props {
  onClick: (val: number) => void;
  value: number;
}

const FlowBox: React.FC<Props> = (props) => {
  const { onClick, value } = props;

  return (
    <StyledFlowBox>
      <StyledInputBox
        title={"Net Flow"}
        unit={"$ / second"}
        placeholder={`± ${value ?? 1}`}
        headerStyle={"transparent"}
      />
      <StyledControl>
        <button onClick={() => onClick(1)}>
          <p>+</p>
        </button>
        <hr />
        <button onClick={() => onClick(-1)}>
          <p>-</p>
        </button>
      </StyledControl>
    </StyledFlowBox>
  );
};

const StyledControl = styled.div`
  border: 1px solid #10bb34;
  border-radius: 4px;
  margin: 0;

  button:active {
    background: rgba(16, 187, 52, 0.25);
  }

  p {
    margin: 0.75rem 0;
  }
`;

const StyledInputBox = styled(InputBox)`
  div.top {
    border-color: #10bb34;
  }
  div.bottom {
    background-color: #196137;
    border-top-width: 1px;
  }
`;

const StyledFlowBox = styled.div`
  display: flex;
  flex-direction: row;

  hr {
    margin: -3px 0 0 0;
    padding: 0;
    border-color: #10bb34;
  }
`;

export default FlowBox;
