import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Header = styled.div`
  grid-column: 3/15;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
`;

export const Body = styled.div`
  grid-column: 3/15;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    flex: 1;
    margin: 3rem 0 0 0;
  }

  @media all ${screenSize.L_MIN} {
    flex-direction: row;

    p {
      margin: 5rem 0 0 0;
    }
  }
`;
