import React, { useEffect, useState } from "react";
import { home } from "../../assets/strings";

import * as S from "./LinkGenerationModal.style";
import { useModalContext } from "../../context/modalContext";
import CopyIcon from "../../assets/svg/icon-copy.svg";
import ExternalIcon from "../../assets/svg/icon-external.svg";
import { DonationDetails } from "../CreateDonationCard";

import "pure-react-carousel/dist/react-carousel.es.css";
import "../../assets/styles/carousel.css";

const LinkGenerationModal: React.FC = () => {
  const [link, setLink] = useState("https://superfluid.finance/donate/m");
  const [hasCopied, setCopied] = useState(false);
  const [modal, _] = useModalContext();

  const data = modal?.data as DonationDetails;

  useEffect(() => {
    // generate link here using data
    // data.address
    // data.amount
    // data.tokens
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => setCopied(false), 1000);
    return () => clearInterval(intervalId);
  }, [hasCopied]);

  const copyLink = (e: any) => {
    setCopied(true);
    navigator.clipboard.writeText("cool");
  };

  const openLink = (e: any) =>
    window.open("http://app.superfluid.finance", "_blank");

  return (
    <S.Wrapper>
      <h2 className="title">{!hasCopied ? `Link generated` : `Copied!`}</h2>
      <p className="subtitle">Share it to receive streaming donations!</p>
      <S.ShareURL>
        <input contentEditable={false} value={link} />
        <button className="icon" onClick={copyLink}>
          <img src={CopyIcon} alt="Copy URL" />
        </button>
        <button className="icon" onClick={openLink}>
          <img src={ExternalIcon} alt="Open URL" />
        </button>
      </S.ShareURL>
    </S.Wrapper>
  );
};

export default LinkGenerationModal;
