import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { ModalType } from "../../../context/modalContext";
import post from "../../../utils/post";
import { track } from "../../../utils/user";
import DataCaptureCard, { InputProp, ValidationType } from "../DataCaptureCard";

interface Props {
  skipFirst?: boolean;
}

const MerchantCaptureModal: React.FC<Props> = ({ skipFirst }) => {
  const [data, setData] = useState<{
    emailAddress?: string;
    websiteURL: string;
    paymentFrequency: string[];
    paymentMethods: string[];
    paymentMethodOption?: string;
  }>();

  const [isComplete, setComplete] = useState(false);

  useEffect(() => {
    if (!isComplete) return;

    // send form data.
    track("Form Submitted", { UserSegment: "merchant", data });
  }, [data]);

  const inputProps: { [index: string]: InputProp } = {
    0: { validation: ValidationType.EMAIL },
    //1: { validation: ValidationType.URL },
    3: { optionalFieldPlaceholder: "BTC, LTC, etc" },
  };

  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "illustration-data-modal-merchant.png" }
      ) {
        ...modalIllustration
      }
    }
  `);

  const handleOnClick = (d?: any, index?: number) => {
    if (!d) return;

    const { inputField, multiSelect } = d;

    switch (index) {
      case 0:
        const emailAddress = inputField as string;
        setData({ ...data, emailAddress });
        post({ emailAddress });
        break;
      case 1:
        const websiteURL = inputField as string;
        setData({ ...data, websiteURL });
        post({ websiteURL });
        break;
      case 2:
        const paymentFrequency = multiSelect as string[];
        setData({ ...data, paymentFrequency });
        post({ paymentFrequency });
        break;
      case 3:
        const paymentMethods = multiSelect as string[];
        const paymentMethodOption =
          inputField !== "" ? (inputField as string) : undefined;
        setComplete(true);
        setData({
          ...data,
          paymentMethods,
          paymentMethodOption,
        });
        post({ paymentMethods, paymentMethodOption });
        break;
    }
  };

  return (
    <DataCaptureCard
      modalType={ModalType.MerchantCapture}
      inputProps={inputProps}
      image={illustration.childImageSharp.fluid}
      onClick={handleOnClick}
      skipFirst={skipFirst}
    />
  );
};

export default MerchantCaptureModal;
