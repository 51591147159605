import styled from "styled-components";
import { screenSize } from "../../utils/theme";
import Button from "../../components/elements/Button";
import FlowBG from "../../assets/svg/bg-get-started-mobile.svg";

export const Body = styled.div`
  ${({ theme }) => theme.mainGridWithPadding};

  margin-top: 2rem;
  box-sizing: border-box;
  background: url(${FlowBG}) no-repeat;
  background-size: 14rem;
  background-position-y: 1rem;

  @media all ${screenSize.M} {
    background-size: 18rem;
  }

  @media all ${screenSize.L_MIN} {
    margin-top: 3.5rem;
    padding-right: 0;
    background: none;
  }
`;

export const Intro = styled.div`
  display: none;

  @media all ${screenSize.L_MIN} {
    display: block;

    p {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
`;

export const Message = styled.p<{ error: boolean }>`
  position: absolute;
  top: 2.25rem;
  font-size: 0.66rem;
  transition: all ease-in-out 0.25s;
  color: ${({ error, theme }) =>
    error ? theme.inputError : theme.colors.grey};
`;

export const MobileIntro = styled.div`
  padding: 2rem;
  box-sizing: border-box;

  @media all ${screenSize.L_MIN} {
    display: none;
  }

  p {
    line-height: 1.5rem;
  }

  @media all ${screenSize.M} {
    padding-right: 20%;
  }
`;

export const MobileGetStarted = styled(Button)`
  margin: 1rem 3rem 2rem 3rem;
`;

export const BodyLeft = styled.div`
  h2 {
    padding-right: 2rem;
  }

  @media all ${screenSize.L_MIN} {
    min-width: 34.5rem;
    flex: 3;
  }
`;

export const BodyRight = styled.div`
  position: relative;
  overflow: hidden;

  div {
    position: absolute;
    left: 0;
    right: auto;
    min-width: 100%;
    top: -2rem;

    @media all ${screenSize.XL} {
      right: 0;
      left: auto;
    }
  }

  @media all ${screenSize.L_MIN} {
    flex: 3;
  }
`;

export const RowContainer = styled.div`
  padding: 0 0 3rem 0;
  min-height: 16rem;

  @media all ${screenSize.L_MIN} {
    margin-top: 3rem;
  }

  h3 {
    margin: 1rem 0 0 0;

    @media all ${screenSize.L_MIN} {
      margin: 1rem 0 0 0;
    }

    @media all ${screenSize.M} {
      margin: 1.25rem 0 0 0;
    }
  }
`;

export const RowImage = styled.div`
  width: 30px;
`;

export const Row = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    div {
      width: 2.75rem;
    }
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  > p {
    margin-left: 2.75rem;
  }

  b {
    font-weight: 500;
  }

  @media all ${screenSize.M_MAX} {
    margin: 2rem;
  }

  @media all ${screenSize.L_MIN} {
    padding: 1rem;

    ${({ active }) =>
      active && {
        padding: "1.25rem 1rem",
        boxShadow:
          "inset 0px -1px 0px 0px rgb(188, 195, 207), 0px 4px 8px 0px rgba(130, 146, 173, 0.16)",
        border: "1px solid rgb(224, 232, 248)",
        borderRadius: "1rem",
        opacity: 1,
      }};

    :hover {
      .row-content {
        max-height: 200px;
        opacity: 1;
        position: relative;
      }

      padding: 1rem;
      border: 1px solid rgb(224, 232, 248);
      box-shadow: inset 0px -1px 0px 0px rgb(188, 195, 207),
        0px 4px 8px 0px rgba(130, 146, 173, 0.16);
      border-radius: 1rem;
      transition: padding 0.5s;
      opacity: 1;
    }
  }

  .row-content {
    transition: opacity 0.5s;
    color: ${({ theme }) => theme.accentColor};

    @media all ${screenSize.L_MIN} {
      ${({ active }) => ({
        maxHeight: active ? 200 : 0,
        opacity: active ? 1 : 0,
        overflow: active ? "visible" : "hidden",
        position: active ? "relative" : "absolute",
      })};
    }
  }

  div {
    min-width: 30px;
  }
`;
