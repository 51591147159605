import React, { FC } from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";

const PerkWrapper = styled.div`
  text-align: center;
  max-width: calc((100% - 2 * 60px) / 3);

  @media all ${screenSize.SMM_MAX} {
    max-width: 100%;
  }
`;

const PerkTitle = styled.h4`
  font-size: 24px;
  margin: 32px 0 12px;

  @media all ${screenSize.SMM_MAX} {
    font-size: 16px;
    margin: 16px 0 8px;
  }
`;

const PerkDescription = styled.p`
  margin-top: 0;
  font-size: 18px;

  @media all ${screenSize.SMM_MAX} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const PerkImage = styled.img`
  max-width: 80px;
  width: 100%;

  @media all ${screenSize.SMM_MAX} {
    max-width: 60px;
  }
`;

interface PerkItemProps {
  img: any;
  title: string;
  description: string;
}

const PerkItem: FC<PerkItemProps> = ({ img, title, description }) => (
  <PerkWrapper>
    <PerkImage src={img} />
    <PerkTitle>{title}</PerkTitle>
    <PerkDescription>{description}</PerkDescription>
  </PerkWrapper>
);

export default PerkItem;
