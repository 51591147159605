import { createGlobalStyle, css, DefaultTheme } from "styled-components";

export const breakPoints = {
  sm: 375,
  smm: 640,
  md: 1024,
  lg: 1440,
  xlg: 1920,
};

export const screenSize = {
  XXL: `and (min-width: ${breakPoints.xlg}px)`,
  XL: `and (min-width: ${breakPoints.lg}px)`,
  L: `and (min-width: ${breakPoints.md}px) and (max-width: ${
    breakPoints.lg - 1
  }px)`,
  L_MIN: `and (min-width: ${breakPoints.md}px)`,
  M_MAX: `and (max-width: ${breakPoints.md - 1}px)`,
  M: `and (min-width: ${breakPoints.sm + 1}px) and (max-width: ${
    breakPoints.md - 1
  }px)`,
  M_MIN: `and (min-width: ${breakPoints.sm + 1}px)`,
  SMM_MIN: `and (min-width: ${breakPoints.smm}px)`,
  SMM_MAX: `and (max-width: ${breakPoints.smm}px)`,
  S_MIN: `and (min-width: ${breakPoints.sm + 1}px)`,
  S_MAX: `and (max-width: ${breakPoints.sm}px)`,
};

export const theme: DefaultTheme = () => ({
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    green: "#10bb34",
    red: "#dc471d",
    grey: "#bcc5d3",
    darkGrey: "#8292ad",
    darkGreen: "#1b4f38",
  },

  textColor: "#12141e",
  accentColor: "#8392ad",

  buttonDefault: "#10bb34",
  buttonHover: "#12d23b",
  buttonActive: "#0ea32e",
  buttonDisabled: "#bcc5d3",

  inputBackground: "#eef7fe",
  inputPlaceholder: "#a2adc1",
  inputColor: "#8392ad",
  inputError: "#dc471d",

  linkColor: "#10bb34",

  checkboxTextColor: "#8292ad",

  protocolBackground: "#21253A",

  maxWidth: `${breakPoints.lg}px`,

  // css snippets
  inputErrorText: css`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.inputError};
    margin: 0.5rem 0;
  `,
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,
  mainPadding: css`
    @media all ${screenSize.L_MIN} {
      padding-left: 4.25rem;
      padding-right: 4.25rem;
    }
    @media all ${screenSize.XL} {
      padding-left: 8.5rem;
      padding-right: 8.5rem;
    }
  `,
  mainGrid: css`
    display: flex;
    grid-column: 1/17;

    @media all ${screenSize.L_MIN} {
      box-sizing: border-box;
      width: 100%;
      max-width: ${({ theme }) => theme.maxWidth};
      justify-self: center;
    }
  `,
  mainGridWithPadding: css`
    ${({ theme }) => theme.mainGrid};
    ${({ theme }) => theme.mainPadding};
  `,
});

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }

  html {
    font-family: "GT Walsheim Pro", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-variant: none;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  * {
    box-sizing: border-box;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    border: 0;
    background: transparent;
  }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  /* Accessibility label hidden */
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
  
  @media all ${screenSize.M_MIN} {
   html {
     font-size: 1.25em;
   }
  }
  
  @media all ${screenSize.L_MIN} {
   h1 {
     font-size: 2.4rem;
     line-height: 3.5rem;
   }
  } 
`;
