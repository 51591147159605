import React, { useEffect, useLayoutEffect, useState } from "react";
import * as S from "./GetStartedSection.style";
import Logo from "../../assets/svg/logo.svg";
import Button from "../../components/elements/Button";

import { home } from "../../assets/strings";
import withLocation, { Props as LocationProps } from "../../utils/withLocation";
import { track } from "../../utils/user";
import useWindowScroll from "react-use/lib/useWindowScroll";
import useMeasure, { UseMeasureRef } from "react-use/lib/useMeasure";
import MoneyIcon from "../../assets/svg/icon-money.svg";
import PadlockIcon from "../../assets/svg/icon-padlock.svg";
import NetworkIcon from "../../assets/svg/icon-network.svg";

import Lottie, { Options } from "react-lottie";
import cubeAnimation from "../../assets/lottie/top-cube.json";
import { ModalType, useModalContext } from "../../context/modalContext";

interface Props extends LocationProps {
  isMobile: boolean;
}

const { getStartedSection: root } = home;

const RowItems = root.flowItems.map((item, i) => ({
  ...item,
  image: [MoneyIcon, PadlockIcon, NetworkIcon][i],
}));

const GetStartedSection: React.FC<Props> = ({ isMobile, search }) => {
  const [activeRow, setActiveRow] = useState<number | undefined>(0);
  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [showedModal, setShowedModal] = useState(false);

  const { y } = useWindowScroll();
  const [ref, { bottom }] = useMeasure();

  const [shouldLottiePlay, setLottiePlay] = useState(false);

  useLayoutEffect(() => {
    setLottiePlay(y < bottom);
  }, [y, bottom]);

  const [_, setModalVisibility] = useModalContext();

  const defaultOptions: Options = {
    loop: true,
    autoplay: false,
    animationData: cubeAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (showedModal) return;

    const { signup } = search;
    if (signup == "invest")
      setModalVisibility({ type: ModalType.InvestorCapture });
    else if (signup == "merchant")
      setModalVisibility({ type: ModalType.MerchantEmailCapture });
    setShowedModal(true);
  });

  /*const isEmailValid = useMemo(() => !!email && validateEmail(email), [email]);

  const handleEmailClick = () => {
    track("Email Input Selected", { header: true });
  };*/

  const handleSubscribeClick = () => {
    //if (isEmailValid) {
    setModalVisibility({ type: ModalType.GetStarted });
    /*post({ email });
      identify({ email });
      track("Email Submitted", { email, header: true });*/
    track("Clicked Get Started");
    setError(false);
    // } else {
    //   setError(true);
    //   setTimeout(() => setError(false), 5000);
    // }
  };

  const handleRowMouseEnter = (i: number) =>
    setActiveRow(i === 0 ? 0 : undefined);

  const handleRowMouseLeave = (i: number) => setActiveRow(i);

  // const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   !!e.target.value && setEmail(e.target.value);

  return (
    <>
      <S.Body ref={ref as UseMeasureRef<HTMLDivElement>}>
        <S.BodyLeft>
          <S.MobileIntro>
            <h1>{root.flowTitle}</h1>
            <p>{root.flowSubtitle}</p>
          </S.MobileIntro>
          <S.Intro>
            <h1>{root.flowTitle}</h1>
            <p>{root.flowSubtitle}</p>
          </S.Intro>
          <S.RowContainer>
            {RowItems.map(({ title, content, alt, image }, i) => (
              <S.Row
                key={title}
                active={i === activeRow}
                onMouseEnter={() => handleRowMouseEnter(i)}
                onMouseLeave={() => handleRowMouseLeave(i)}
              >
                <div>
                  <S.RowImage>
                    <img src={image} alt={alt} />
                  </S.RowImage>
                  <p>
                    <b>{title}</b>
                  </p>
                </div>
                <p className="row-content">{content}</p>
              </S.Row>
            ))}
          </S.RowContainer>
        </S.BodyLeft>
        {!isMobile && (
          <S.BodyRight>
            <Lottie
              options={defaultOptions}
              height={"auto"}
              width={600}
              isPaused={!shouldLottiePlay}
            />
          </S.BodyRight>
        )}
      </S.Body>
    </>
  );
};

export default withLocation(GetStartedSection);
