import React from "react";
import * as S from "./Card.style";
import Button from "./elements/Button";
import { FluidObject } from "gatsby-image";
import { track } from "../utils/user";

export interface Props {
  index: number;
  image: FluidObject | FluidObject[];
  title: string;
  subtitle: string;
  ctaTitle: string;
  onClick: (index: number) => void;
}

const Card: React.FC<Props> = (props) => {
  const { image, title, subtitle, ctaTitle, onClick, index } = props;

  const handleClick = () => {
    track("Card Chosen", {
      title,
      subtitle,
      ctaTitle,
    });
    onClick(index);
  };

  return (
    <S.Wrapper>
      <S.Image fluid={image} fadeIn={false} loading={"eager"} />
      <S.Container>
        <h4>{title}</h4>
        <p>{subtitle}</p>
        <Button
          context={title + " " + subtitle}
          title={ctaTitle}
          onClick={handleClick}
        />
      </S.Container>
    </S.Wrapper>
  );
};
export default Card;
