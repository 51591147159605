import React from "react";
import { home } from "../../assets/strings";
import ArrowHorizontalIcon from "../../assets/svg/icon-arrow-horizontal.svg";
import ArrowIcon from "../../assets/svg/icon-arrow-vertical.svg";
import MultiplyIcon from "../../assets/svg/icon-multiply.svg";
import PlusIcon from "../../assets/svg/icon-plus.svg";
import HorizontalLine from "../../components/elements/HorizontalLine";
import VerticalSpacer from "../../components/elements/VerticalSpacer";
import FlowBox from "../../components/FlowBox";
import InputBox from "../../components/InputBox";
import RealTimeBalance from "../../components/RealTimeBalance";
import useDimensions from "../../hooks/useDimensions";
import { ProtocolSectionProps } from "../../types";
import { breakPoints } from "../../utils/theme";
import ProtocolSectionBase from "./ProtocolSectionBase";
import * as S from "./ProtocolSectionPageG.style";

const { protocolSection: root } = home;

const formatWithSymbol = (val: number) => {
  const symbol = val > 0 ? "+" : "";
  return `${symbol}${val}`;
};

interface Props extends ProtocolSectionProps {
  updateNetFlow: (i: number) => void;
  handleFlowCreationClick: () => void;
}

const ProtocolSectionPageG: React.FC<Props> = (props) => {
  const slide = root.slides["G"];

  const {
    onSetIndex,
    index,
    data,
    updateNetFlow,
    handleFlowCreationClick,
  } = props;

  const { width } = useDimensions();
  const isMobile = width < breakPoints.md;

  const {
    balance,
    staticBalance,
    timeElapsed,
    flowAgreement,
    netFlow,
    netFlowRate,
    fromEntries,
    toEntries,
  } = data;

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Wrapper>
        <S.TopRow>
          <div className="balance">
            <S.HorizontalArrow src={ArrowHorizontalIcon} alt="arrow icon" />
            <RealTimeBalance isMobile={isMobile} value={balance} />
          </div>
          <div />
          <S.VerticalArrow src={ArrowIcon} alt="arrow icon" />
          <VerticalSpacer size={2} />
          <S.DashedBox>
            <InputBox title={slide.staticBoxTitle} value={`${staticBalance}`} />
            <S.MiddleBox>
              <S.IconImage src={PlusIcon} alt="plus icon" />
            </S.MiddleBox>
            <InputBox
              title={slide.flowBoxTitle}
              value={`${flowAgreement}`}
              headerStyle="opaque"
            />
          </S.DashedBox>
        </S.TopRow>
        <HorizontalLine middleLineXPos={48.5} />
        <VerticalSpacer size={2} isLine={true} />
        <S.MiddleRow>
          <div className="text">
            <p>
              {/*{slide.bobReceivingTextA} {formatWithSymbol(netFlowRate)}
              {slide.bobReceivingTextB}*/}
              {slide.bobReceivingTextC}
            </p>
            <p>{slide.bobReceivingTextD}</p>
          </div>
          <div />
          <VerticalSpacer size={2} />
          <div className="boxes">
            <InputBox
              title={slide.lastUpdateTitle}
              value={`${timeElapsed}`}
              unit={slide.lastUpdateUnit}
              headerStyle="transparent"
            />
            <S.MiddleBox>
              <S.IconImage src={MultiplyIcon} alt="plus icon" />
            </S.MiddleBox>
            <InputBox
              title={slide.netflowRateTitle}
              value={formatWithSymbol(netFlowRate)}
              unit={slide.netflowRateUnit}
              headerStyle="transparent"
            />
          </div>
        </S.MiddleRow>
        <VerticalSpacer size={2} />
        <HorizontalLine />
        <S.BottomRow>
          <div className="boxes">
            <div className="flow">
              <FlowBox
                value={netFlow}
                onClick={(e) => updateNetFlow(e)}
                headerStyle="#21253a"
              />
              <VerticalSpacer size={1} />
              <S.FlowButton onClick={handleFlowCreationClick}>
                <p>{slide.createFlowTitle}</p>
              </S.FlowButton>
            </div>
            <VerticalSpacer size={2} />
            <S.MiddleBox />
            <S.Entries>
              {[fromEntries, toEntries].map((entries, i) => (
                <p className="text" key={`entry-${i}`}>
                  {entries.map((entry) => (
                    <>
                      {i == 0 ? `from mike.eth ` : `to unicorn.eth `}
                      {formatWithSymbol(entry)}/s
                      <br />
                    </>
                  ))}
                </p>
              ))}
            </S.Entries>
          </div>
          <div />
          <div className="text">
            {/*<p>{slide.bobStreamTextA}</p>*/}
            <p>{slide.bobStreamTextB}</p>
            <p>{slide.bobStreamTextC}</p>
          </div>
        </S.BottomRow>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageG;
