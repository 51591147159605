import styled from "styled-components";
import { screenSize } from "../../utils/theme";
import CurveBackground from "../../assets/svg/bg-dashboard-curve-reverse.svg";
import GatsbyImage from "gatsby-image";

export const Wrapper = styled.div`
  ${({ theme }) => theme.mainGridWithPadding};

  padding: 8rem 1rem 1rem;
  justify-content: space-between;
  flex-direction: column;
  background: url(${CurveBackground}) no-repeat;
  background-size: 120%;
  background-position-x: 75%;
  background-position-y: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media all ${screenSize.M} {
    background-position-x: 50%;
    padding: 7.5rem 1rem;
  }

  @media all ${screenSize.L_MIN} {
    padding: 0rem 2rem 5rem;
    background-size: 100% 100%;
    margin-bottom: 4.25rem;
  }
`;

export const Donate = styled.div`
  margin-bottom: 4rem;
  padding: 0 0.75rem;

  h2.title {
    margin: 0;
    text-align: center;
  }

  p.subtitle {
    margin-bottom: 0;
    text-align: center;
  }

  @media all ${screenSize.L_MIN} {
    flex: 4;
    margin-top: 1.5rem;
    padding-left: 6rem;

    h2.title {
      font-size: 2.4rem;
      line-height: 2.75rem;
      font-weight: normal;
    }

    p.subtitle {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
`;

export const Illustration = styled(GatsbyImage)`
  display: none;
  margin: 0 0 4em 0;

  width: 100%;
  display: block;
  max-height: 40vh;
`;
