import { withPrefix } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const SITE_LANG = "en";
const AUTHOR = "Superfluid";

interface Props {
  description: string;
  title: string;
}

const SEO: React.FC<Props> = ({ title, description, children }) => (
  <Helmet
    htmlAttributes={{ lang: SITE_LANG, prefix: "og: http://ogp.me/ns#" }}
    meta={[
      { name: `title`, content: title },
      { name: `description`, content: description },
      { name: `author`, content: AUTHOR },
      { name: `twitter:card`, content: `summary_large_image` },
      { name: `twitter:site`, content: `Superfluid_HQ` },
      { name: `twitter:creator`, content: `@Superfluid_HQ` },
      {
        name: `twitter:title`,
        content: `Superfluid - Rails for Real-Time Finance`,
      },
      {
        name: `twitter:description`,
        content:
          "Superfluid is a new Ethereum Protocol that extends Ethereum tokens to include novel functionalities. Superfluid enables functionalities like money streaming and reward distribution. ",
      },
      {
        name: `twitter:image`,
        content: "https://www.superfluid.finance/superfluid-thumbnail.png",
      },
      { property: `og:type`, content: `website` },
      { property: `og:url`, content: `https://superfluid.finance` },
      {
        property: `og:title`,
        content: `Superfluid - Rails for Real-Time Finance`,
      },
      {
        property: `og:description`,
        content: `Superfluid is a new Ethereum Protocol that extends Ethereum tokens to include novel functionalities. Superfluid enables functionalities like money streaming and reward distribution.`,
      },
      {
        property: `og:image`,
        content: `https://www.superfluid.finance/superfluid-thumbnail.png`,
      },
    ]}
    title={title}
  >
    <script src={withPrefix(`analytics.js`)} type="text/javascript" />
    <script src={withPrefix(`privacy.js`)} type="text/javascript" />
    {children}
  </Helmet>
);

export default SEO;
