import styled from "styled-components";
import Img, { FluidObject } from "gatsby-image";
import { screenSize } from "../../utils/theme";

export const Header = styled.div`
  grid-column: 3/15;
  display: flex;
  flex-direction: column;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
  }

  h3 {
    flex: 1;
    margin-bottom: 0;
  }

  p {
    flex: 1;
    margin-top: 48px;
  }
`;

export const Content = styled.div`
  grid-column: 3/15;
`;

export const Illustration = styled(Img)<{ fluid: FluidObject }>`
  box-sizing: border-box;
  margin: 4rem 0;
  align-self: center;
  width: 100%;

  img {
    margin-left: -3rem;
  }

  @media all ${screenSize.M} {
    grid-column: 1/13;
  }

  @media all ${screenSize.L_MIN} {
    align-self: flex-start;
    grid-column: 1/4;
    margin: 0;
    width: 20rem;
  }
`;

export const Body = styled.div`
  grid-column: 3/15;
  display: flex;
`;

export const VerticalSpacer = styled.div`
  height: 5.75rem;
`;

export const HorizontalSpacer = styled.div`
  width: 7.25rem;
`;
