import React from "react";
import styled from "styled-components";
import DiscordIcon from "../../assets/svg/icon-discord.svg";
import GithubIcon from "../../assets/svg/icon-github.svg";
import LinkedInIcon from "../../assets/svg/icon-linkedin.svg";
import TwitterIcon from "../../assets/svg/icon-twitter.svg";
import Link from "./Link";

type SocialType = "twitter" | "github" | "linkedin" | "discord";

export interface SocialLink {
  type: SocialType;
  attr: string;
}

interface Props {
  links: SocialLink[];
  isSmall?: boolean;
}

const Wrapper = styled.div<{ isSmall?: boolean }>`
  margin: ${({ isSmall }) => (isSmall ? `0` : `1rem 0`)};
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin-right: ${({ isSmall }) => (isSmall ? `0.5rem` : `0.75rem`)};
    width: ${({ isSmall }) => isSmall && `18px`};
  }

  a {
    display: inline;
    opacity: ${({ isSmall }) => isSmall && `0.25`};

    :hover {
      opacity: 0.75;
    }
  }
`;

const createMappedLink = ({
  type,
  attr,
}: SocialLink): { text: string; href: string; icon: any } => {
  return {
    twitter: {
      text: type,
      href: `https://twitter.com/intent/follow?screen_name=${attr}`,
      icon: TwitterIcon,
    },
    github: {
      text: type,
      href: `https://github.com/${attr}`,
      icon: GithubIcon,
    },
    discord: {
      text: type,
      href: `https://discord.gg/${attr}`,
      icon: DiscordIcon,
    },
    linkedin: {
      text: type,
      href: `https://www.linkedin.com/${attr}`,
      icon: LinkedInIcon,
    },
  }[type];
};

const SocialIcons: React.FC<Props> = ({ isSmall, links }) => {
  if (!links.length) return null;

  const mappedSocialLinks = links.map((link) => createMappedLink(link));

  return (
    <Wrapper isSmall={isSmall}>
      {mappedSocialLinks.map(({ text, href, icon }) => (
        <Link text={text} href={href} target="_blank" key={text}>
          <img src={icon} alt={text} />
        </Link>
      ))}
    </Wrapper>
  );
};

export default SocialIcons;
