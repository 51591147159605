import React, { useRef } from "react";


import { home } from "../../assets/strings";
import Wrapper from "../Wrapper";
import MiddleStreamSection from "../../sections/middle-stream/MiddleStreamSection";
import ProtocolSection from "../../sections/protocol/ProtocolSection";
import SubscriptionSection from "../../sections/subscription/SubscriptionSection";
import DashboardSection from "../../sections/dashboard/DashboardSection";
import GetStartedSection from "../../sections/get-started/GetStartedSection";
import Header from "../../sections/header/Header";
import Footer from "../../sections/footer/Footer";
import DonateSection from "../../sections/donate/DonateSection";

const { meta } = home;

export default () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper title={meta.title} description={meta.description} ref={wrapperRef}>
      <Header />
      <GetStartedSection />
      <MiddleStreamSection />
      {/* <DonateSection /> */}
      {/* <SubscriptionSection /> */}
      <DashboardSection />
      <ProtocolSection />
      <Footer />
    </Wrapper>
  );
};
