import React, { FC, useEffect, useMemo, useState } from "react";
import * as S from "./Footer.style";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import Link from "../../components/elements/Link";
import post from "../../utils/post";

import { FooterLink, home } from "../../assets/strings";
import SocialIcons, { SocialLink } from "../../components/elements/SocialIcons";
import { ModalType, useModalContext } from "../../context/modalContext";
import { validateEmail } from "../../utils/validation";
import { identify, track } from "../../utils/user";

const { footer: root } = home;

const socialLinks: SocialLink[] = [
  {
    type: "twitter",
    attr: "Superfluid_HQ",
  },
  {
    type: "github",
    attr: "superfluid-finance",
  },
  {
    type: "discord",
    attr: "XsK7nahanQ",
  },
  {
    type: "linkedin",
    attr: "company/37856773/",
  },
];

interface FooterProps {
  showSub?: boolean;
}

const Footer: FC<FooterProps> = ({ showSub = true }) => {
  const [_, setModalVisibility] = useModalContext();

  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);

  const isEmailValid = useMemo(() => !!email && validateEmail(email), [email]);

  const handleInvestorClick = () => {
    setModalVisibility({ type: ModalType.InvestorCapture });
    track("Link Clicked", { UserSegment: "investor" });
  };

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) return;

    window.Intercom?.("boot", {
      app_id: "o7qlnuli",
      custom_launcher_selector: "#support_chat",
    });
  }, []);

  const handleSubscribeClick = () => {
    if (typeof window === "undefined" || !window.document) return;

    if (!isEmailValid) {
      return setError(true);
    } else {
      setError(false);
    }

    post({ email }); // post registers user, so getId() will retrieve an id
    identify({ email });
    track("Email Submitted", { email, footer: true });
    setModalVisibility({ type: ModalType.GetStarted });
  };

  const handleEmailClick = () => {
    track("Email Input Selected", { footer: true });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    !!e.target.value && setEmail(e.target.value);

  const handleSupportClick = () => {
    if (typeof window === "undefined" || !window.document) return;

    window.Intercom?.("show");
  };

  // Map index to functionality here.
  const onClickIndexMapping = {
    0: handleSupportClick,
    1: handleInvestorClick,
  };

  const renderFooterText = (item: FooterLink) => {
    const hasCustomLogic = item.onClickIndex !== undefined;

    // Default url
    if (!!item.url)
      return (
        <Link href={item.url} target="_blank">
          {item.text}
        </Link>
      );

    // Custom url
    if (hasCustomLogic)
      return (
        <a onClick={onClickIndexMapping[item.onClickIndex]}>{item.text}</a>
      );

    // Default appearance
    return (
      <p>
        <span>{item.text}</span>
      </p>
    );
  };

  return (
    <S.Wrapper>
      {showSub && (
        <S.MobileConnect>
          <S.MobileConnectHead>
            <p>
              {root.tempTitle}
              <br />
              {root.tempSubtitle}
            </p>
          </S.MobileConnectHead>
          <S.Subscribe>
            <div>
              <Input
                placeholder={root.inputPlaceholder}
                onChange={handleEmailChange}
                onClick={handleEmailClick}
              />
              <Button
                title={root.subscribeButtonTitle}
                onClick={handleSubscribeClick}
              />
            </div>
            <S.Message error={error}>
              {error ? root.inputError : root.inputMessage}
            </S.Message>
          </S.Subscribe>
          <SocialIcons links={socialLinks} />
        </S.MobileConnect>
      )}

      <S.Links>
        {root.sections.map(({ title, list }) => (
          <S.Section>
            <h3>{title}</h3>
            {list.map((item, index) => (
              <React.Fragment key={`footer-${index}`}>
                {renderFooterText(item)}
              </React.Fragment>
            ))}
          </S.Section>
        ))}
      </S.Links>
      <S.Copyright>
        © 2022 Superfluid Finance LTD -{" "}
        <Link
          href="https://www.iubenda.com/privacy-policy/34415583/legal"
          target="_blank"
        >
          Privacy Policy
        </Link>{" "}
        -{" "}
        <Link href="/termsofuse" target="_blank">
          {" "}
          Terms of Use
        </Link>
      </S.Copyright>
    </S.Wrapper>
  );
};

export default Footer;
