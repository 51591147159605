import styled from "styled-components";
import React from "react";
import { screenSize } from "../utils/theme";
import GatsbyImage, { FluidObject } from "gatsby-image";
import SocialIcons, { SocialLink } from "./elements/SocialIcons";

interface Props {
  className?: string;
  name: string;
  role: string;
  image?: FluidObject | FluidObject[];
  social?: SocialLink[];
}

const TeamCard: React.FC<Props> = (props: Props) => {
  const { className, name, role, image, social } = props;

  return (
    <StyledTeamCard className={className} border={!image}>
      <GatsbyImage
        fluid={image}
        fadeIn={false}
        loading={"eager"}
        alt={`profile of ${name}`}
        imgStyle={{ objectFit: "contain", borderRadius: `0.5rem` }}
      />
      <h5>{name}</h5>
      <p>{role}</p>
      {!!social && <SocialIcons links={social} isSmall={true} />}
    </StyledTeamCard>
  );
};

const StyledTeamCard = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(33% - 2rem);
  margin: 1rem;
  box-sizing: border-box;
  border: ${({ border }) => border && `1px solid #eee`};
  border-radius: ${({ border }) => border && `0.5rem`};
  overflow: hidden;

  h5 {
    font-weight: 500;
    line-height: 1rem;
    margin: 0.25rem 0 0;
  }

  p {
    font-size: 0.625rem;
    line-height: 0.875rem;
    margin: 0;
    color: ${({ theme }) => theme.accentColor};
  }

  @media all ${screenSize.M} {
    flex: 0 1 calc(25% - 2rem);
    margin-bottom: 2rem;

    h5 {
      margin: 0.5rem 0 0;
    }

    p {
      margin: 0 0 0.5rem;
    }
  }

  @media all ${screenSize.L_MIN} {
    flex: 0 1 calc(20% - 2rem);
    margin-bottom: 3rem;

    h5 {
      margin: 0.75rem 0 0;
    }

    p {
      margin: 0 0 0.75rem;
    }
  }
`;

export default TeamCard;
