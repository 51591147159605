import React from "react";
import styled from "styled-components";
import AnimatedNumber from "animated-number-react";
import CornersTop from "../assets/svg/bg-balance-top.svg";
import CornersBottom from "../assets/svg/bg-balance-bottom.svg";
import { screenSize } from "../utils/theme";

interface Props {
  value: number;
  timer?: number;
  isMobile: boolean;
  className?: string;
}

const zeroPad = (num, places) => String(num).padStart(places, "0");

const RealTimeBalance = (props: Props) => {
  const { value, isMobile, className, timer } = props;

  const formatValue = (v: number) => `${v.toFixed(2)}`;

  const formatTime = (v?: number) => {
    if (!v) return "0:00:00";

    const hours = Math.floor(v / 60 / 60);
    const minutes = zeroPad(Math.floor(v / 60), 2);
    const seconds = zeroPad(v % 60, 2);
    return `${hours}:${minutes}:${seconds}`;
  };

  const showTimer = !!timer;

  return (
    <div className={className}>
      {isMobile ? (
        <>
          {showTimer && (
            <div className="timer">
              <p>{formatTime(timer)}</p>
            </div>
          )}
          <div className="balance">
            <StyledCorners direction="top" />
            <h4>My Real-Time Balance</h4>
            <span className="animated">{formatValue(value)}</span>
            <StyledCorners direction="bottom" />
          </div>
        </>
      ) : (
        <>
          {showTimer && <div className="spacer" />}
          <div className="balance">
            <StyledCorners direction="top" />
            <h4>My Real-Time Balance</h4>
            <span className="animated">{formatValue(value)}</span>
            <StyledCorners direction="bottom" />
          </div>
          {showTimer && (
            <div className="timer">
              <p>{formatTime(timer)}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const StyledCorners = styled.div<{ direction: "top" | "bottom" }>`
  background-image: ${({ direction }) =>
    direction === "top" ? `url(${CornersTop})` : `url(${CornersBottom})}`};
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding: 5% 0;
  margin-top: ${({ direction }) => direction === "bottom" && `-0.5rem`};
`;

const StyledRealTimeBalance = styled(RealTimeBalance)`
  box-sizing: border-box;
  font-family: "AndaleMono", Courier New, Courier, monospace;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
    align-items: baseline;
  }

  h4,
  p {
    margin: 0;
  }

  span.animated {
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.green};
  }

  div.spacer {
    flex: 1;
  }

  div.timer {
    justify-content: center;
    display: flex;
    margin: 2.25rem 0;

    @media all ${screenSize.L_MIN} {
      flex: 1;
    }

    p {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.green};
      border: 2px solid ${({ theme }) => theme.colors.darkGreen};
      border-radius: 4px;
      padding: 0 1rem;
    }
  }

  div.timer > div {
    border: 2px solid rgb(27, 79, 56);
    border-radius: 0.25rem;
    height: auto;
  }

  div.left > div {
    opacity: 0;
  }

  div.balance {
    display: flex;
    align-items: center;
    flex-direction: column;

    h4 {
      margin-top: -0.5rem;
      padding: 0 1rem;
      text-align: center;

      @media all ${screenSize.L_MIN} {
        padding: 0 2rem;
      }
    }
  }

  h4 {
    font-size: 1rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 0.5rem;
    display: block;
  }
`;

export default StyledRealTimeBalance;
