import { user } from "./user";

const scriptURL =
  "https://script.google.com/macros/s/AKfycbyTYw2nDoFJHBdpr3vUZ63cccOdtvcQIhcJ_10niDCBCUKWJZmO/exec";

export default function post(data) {
  fetch(scriptURL, {
    method: "POST",
    body: JSON.stringify({ ...data, id: user() }),
  })
    .then((response) => true)
    .catch((error) => error.message);
}
