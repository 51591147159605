import React, { useEffect, useState } from "react";
import { home } from "../../assets/strings";
import ArrowHorizontalIcon from "../../assets/svg/icon-arrow-horizontal.svg";
import ArrowIcon from "../../assets/svg/icon-arrow-vertical.svg";
import DivideIcon from "../../assets/svg/icon-divide.svg";
import MultiplyIcon from "../../assets/svg/icon-multiply.svg";
import PlusIcon from "../../assets/svg/icon-plus.svg";
import HorizontalLine from "../../components/elements/HorizontalLine";
import VerticalSpacer from "../../components/elements/VerticalSpacer";
import InputBox from "../../components/InputBox";
import RealTimeBalance from "../../components/RealTimeBalance";
import useDimensions from "../../hooks/useDimensions";
import { ProtocolSectionProps } from "../../types";
import { breakPoints } from "../../utils/theme";
import ProtocolSectionBase from "./ProtocolSectionBase";
import * as S from "./ProtocolSectionPageI.style";

const { protocolSection: root } = home;

interface Props extends ProtocolSectionProps {
  updateInitialAgreement: (val: number) => void;
}

const ProtocolSectionPageI: React.FC<Props> = (props) => {
  const { onSetIndex, index, updateInitialAgreement } = props;
  const slide = root.slides["I"];

  const [rewardsAmount, setRewardsAmount] = useState(400);
  const [totalIssuedUnit, setTotalUnits] = useState(100);
  const indexSinceLastUpdate = rewardsAmount / totalIssuedUnit;
  const bobUnitAmount = 20;
  const initialAgreement = bobUnitAmount * indexSinceLastUpdate;
  const staticBalance = 100;
  const realTimeBalance = staticBalance + initialAgreement;

  const { width } = useDimensions();

  const isMobile = width < breakPoints.md;

  useEffect(() => {
    if (!initialAgreement) return;
    updateInitialAgreement(initialAgreement);
  }, [initialAgreement]);

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Wrapper>
        <S.TopRow>
          <div className="balance">
            <S.HorizontalArrow src={ArrowHorizontalIcon} alt="arrow icon" />
            <RealTimeBalance isMobile={isMobile} value={realTimeBalance} />
          </div>
          <S.VerticalArrow src={ArrowIcon} alt="plus icon" />
          <VerticalSpacer size={1} />
          <S.DashedBox>
            <InputBox
              title={slide.staticInputTitle}
              value={`${staticBalance}`}
              titleAlign="center"
              headerStyle="transparent"
            />
            <S.IconImage src={PlusIcon} alt="plus icon" />
            <InputBox
              title={slide.idaInputTitle}
              value={initialAgreement.toFixed(2)}
              titleAlign="center"
              headerStyle="opaque"
            />
          </S.DashedBox>
        </S.TopRow>
        <HorizontalLine middleLineXPos={50.5} />
        <VerticalSpacer size={2} isLine={true} />
        <S.MiddleRow>
          <div className="text">
            <p>{slide.bobTextA}</p>
            <p>{slide.bobTextB}</p>
          </div>
          <VerticalSpacer size={1} />
          <S.DashedBox>
            <InputBox
              title={slide.bobInputTitle}
              value={`${bobUnitAmount}`}
              titleAlign="center"
              headerStyle="transparent"
            />
            <S.IconImage src={MultiplyIcon} alt="plus icon" />
            <InputBox
              title={slide.indexInputTitle}
              value={`${indexSinceLastUpdate.toPrecision(2)}`}
              titleAlign="center"
              headerStyle="transparent"
            />
          </S.DashedBox>
        </S.MiddleRow>
        <HorizontalLine middleLineXPos={50.5} />
        <VerticalSpacer size={2} isLine={true} />
        <S.BottomRow>
          <div className="text">
            <p>{slide.potatoText}</p>
          </div>
          <S.DashedBox>
            <VerticalSpacer size={1} />
            <InputBox
              title={slide.rewardsInputTitle}
              value={`${rewardsAmount}`}
              titleAlign="center"
              editable={true}
              onChange={(e) => setRewardsAmount(Number(e.target.value))}
              shouldBlink={true}
            />
            <S.IconImage src={DivideIcon} alt="divide icon" />
            <InputBox
              title={slide.totalInputTitle}
              titleAlign="center"
              value={`${totalIssuedUnit}`}
              editable={true}
              onChange={(e) => setTotalUnits(Number(e.target.value))}
              shouldBlink={true}
            />
          </S.DashedBox>
        </S.BottomRow>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageI;
