import React from "react";

import { home } from "../../assets/strings";
import * as S from "./ProtocolSectionPageC.style";
import ProtocolSectionBase from "./ProtocolSectionBase";
import RealTimeBalance from "../../components/RealTimeBalance";
import { useStaticQuery, graphql } from "gatsby";
import useDimensions from "../../hooks/useDimensions";
import { breakPoints } from "../../utils/theme";
import { ProtocolSectionProps as Props } from "../../types";

const { protocolSection: root } = home;

const ProtocolSectionPageC: React.FC<Props> = ({
  onSetIndex,
  index,
  data: { balance, timeElapsed },
}) => {
  const slide = root.slides["C"];

  const { width } = useDimensions();

  const isMobile = width < breakPoints.md;

  const mappedDescriptions = slide.descriptions.map((str) => (
    <p dangerouslySetInnerHTML={{ __html: str }} />
  ));

  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(relativePath: { eq: "illustration-balance.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Header>
        <h3>{slide.title}</h3>
        {!isMobile && mappedDescriptions[0]}
      </S.Header>
      {isMobile ? (
        <>
          <S.Illustration
            fluid={illustration.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            fadeIn={false}
            loading={"eager"}
          />
          <S.Content>{mappedDescriptions[0]}</S.Content>
        </>
      ) : (
        <S.Illustration
          fluid={illustration.childImageSharp.fluid}
          imgStyle={{ objectFit: "contain" }}
          fadeIn={false}
          loading={"eager"}
        />
      )}
      <S.Body>
        <RealTimeBalance
          isMobile={isMobile}
          value={balance}
          timer={timeElapsed}
        />
      </S.Body>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageC;
