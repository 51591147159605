import styled from "styled-components";
import { DotGroup } from "pure-react-carousel";
import { screenSize } from "../../utils/theme";

export const Wrapper = styled.div`
  background: white;
  width: 24rem;
  border-radius: 0.25rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.7rem 2.375rem;
  box-sizing: border-box;

  h2.title {
    font-size: 1.4em;
    margin: 0;
    position: relative;
  }

  h2.title ::after {
    content: "🎉";
    font-size: 0.9em;
    position: absolute;
    top: 0;
    right: -2rem;
  }

  p.subtitle {
    font-size: 0.8rem;
    margin: 0;
  }
`;

export const ShareURL = styled.div`
  background: ${({ theme }) => theme.inputBackground};
  height: 2.5rem;
  width: 100%;
  display: flex;
  border-radius: 0.25rem;
  justify-content: flex-end;
  margin-top: 1.5rem;

  input {
    flex: 1;
    width: 100%;
    padding: 0 0.75rem;
    font-size: 0.7em;
    color: ${({ theme }) => theme.colors.darkGrey};
  }

  button.icon {
    width: 2.5rem;
    height: 100%;
    border-left: 1px solid #d3e5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #e6eef5;
    }

    &:active {
      background: #e1eaf1;
    }
  }
`;
