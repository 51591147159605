import React from "react";
import * as S from "./ProtocolSectionIndicator.style";

interface Props {
  index: number;
  count: number;
  onClick: (i: number) => void;
}

const ProtocolSectionIndicator: React.FC<Props> = (props) => {
  const { index, count, onClick } = props;

  return (
    <S.Wrapper>
      {[...Array(count).keys()].map((i) => {
        if (i === index) {
          return <S.ActiveBox />;
        } else {
          return <S.InactiveBox onClick={() => onClick(i)} />;
        }
      })}
    </S.Wrapper>
  );
};

export default ProtocolSectionIndicator;
