import React, { FC, useState } from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";
import Accordion from "./Accordion";
import Flex from "./Flex";
import OpenIndicator from "./OpenIndicator";

const FAQWrapper = styled.div`
  border-bottom: 1px solid #d4d4d4;
  padding: 16px 0;
  overflow: hidden;

  @media all ${screenSize.SMM_MAX} {
    padding: 8px 0;
  }
`;

const Question = styled.h5`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 12px 0;

  @media all ${screenSize.SMM_MAX} {
    font-size: 16px;
  }
`;

const Answer = styled.p<{ height?: number }>`
  margin: 0;
  font-size: 16px;
  color: #717171;
  padding-bottom: 12px;
  max-width: 80%;
  white-space: break-spaces;

  @media all ${screenSize.SMM_MAX} {
    font-size: 14px;
  }
`;

const Trigger = styled(Flex)`
  cursor: pointer;
  margin-right: 32px;
  gap: 12px;

  @media all ${screenSize.SMM_MAX} {
    margin-right: 12px;
  }
`;

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: FC<FAQItemProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <FAQWrapper>
      <Trigger align="center" justify="space-between" onClick={toggleOpen}>
        <Question>{question}</Question>
        <OpenIndicator open={open} />
      </Trigger>
      <Accordion open={open}>
        <Answer dangerouslySetInnerHTML={{ __html: answer }} />
      </Accordion>
    </FAQWrapper>
  );
};

export default FAQItem;
