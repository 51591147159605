import React, { useState } from "react";
import styled from "styled-components";
import VideoPlaceholderGif from "../../assets/gif/reactor-video-placeholder.gif";
import { reactor } from "../../assets/strings";
import CloseIcon from "../../assets/svg/icon-close-white.svg";
import GoToMarketIcon from "../../assets/svg/icon-gotomarket.svg";
import IntroductionsIcon from "../../assets/svg/icon-introductions.svg";
import MarketingIcon from "../../assets/svg/icon-marketing.svg";
import ProductIcon from "../../assets/svg/icon-product.svg";
import TechnicalGuidanceIcon from "../../assets/svg/icon-technicalguidance.svg";
import {
  default as Title1Icon,
  default as Title2Icon,
} from "../../assets/svg/icon-title1.svg";
import LineDesktop from "../../assets/svg/reactor-desktop.svg";
import ReactorIllustration1 from "../../assets/svg/reactor-illustration1.svg";
import ReactorIllustration2 from "../../assets/svg/reactor-illustration2.svg";
import ReactorIllustration3 from "../../assets/svg/reactor-illustration3.svg";
import LineMobile from "../../assets/svg/reactor-mobile.svg";
import Footer from "../../sections/footer/Footer";
import Header from "../../sections/header/Header";
import { screenSize } from "../../utils/theme";
import Button from "../elements/Button";
import Link from "../elements/Link";
import FAQItem from "../FAQItem";
import OverviewItem from "../OverviewItem";
import PerkItem from "../PerkItem";
import Wrapper from "../Wrapper";

const Section = styled.section`
  ${({ theme }) => theme.mainGridWithPadding};
  margin: 0 auto 120px;
  display: block;
  width: 100%;

  @media all ${screenSize.SMM_MAX} {
    max-width: calc(100% - 4rem);
    margin: 0 auto 80px;
  }
`;

const H1 = styled.h1`
  font-size: 48px;
  white-space: break-spaces;
  margin: 0;
  line-height: 120%;

  @media all ${screenSize.SMM_MAX} {
    font-size: 35px;
  }
`;

const H2 = styled.h2`
  font-size: 42px;
  text-align: center;
  margin: 0;

  @media all ${screenSize.SMM_MAX} {
    font-size: 28px;
  }
`;

const H3 = styled.h3`
  font-size: 36px;
  text-align: center;
  margin: 0 0 80px;

  @media all ${screenSize.SMM_MAX} {
    font-size: 28px;
    margin-bottom: 40px;
  }
`;

const HeroSection = styled(Section)`
  ${({ theme }) => theme.mainGridWithPadding};
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 600px;

  @media all ${screenSize.SMM_MAX} {
    flex-direction: column;
    row-gap: 40px;
  }
`;

const HeroContent = styled.div`
  max-width: 400px;

  @media all ${screenSize.SMM_MAX} {
    margin-top: 64px;
  }
`;

const HeroDescription = styled.p`
  margin: 0;
  margin: 24px 0;

  @media all ${screenSize.SMM_MAX} {
    font-size: 20px;
  }
`;

const OverviewSection = styled(Section)`
  overflow: hidden;
`;

const FAQList = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const PerkList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 80px;
  column-gap: 60px;
  margin: 80px 0 0;

  @media all ${screenSize.SMM_MAX} {
    row-gap: 40px;
    margin: 40px 0;
  }
`;

const OverviewWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  min-height: 1069px;
  position: relative;
  margin: 40px auto 0;

  > div {
    position: absolute;
  }

  p {
    max-width: 640px;
  }

  @media all ${screenSize.SMM_MAX} {
    min-height: 467px;
  }

  > div:nth-child(1) {
    top: 9%;
    left: 5%;

    p {
      max-width: 540px;
    }

    @media all ${screenSize.SMM_MAX} {
      top: 6%;
      left: 10%;
    }
  }

  > div:nth-child(2) {
    top: 39%;
    left: 25%;

    p {
      max-width: 600px;
    }

    @media all ${screenSize.SMM_MAX} {
      top: 37%;
      left: 9%;
    }
  }

  > div:nth-child(3) {
    top: 71%;
    left: 15%;

    p {
      max-width: 440px;
    }

    @media all ${screenSize.SMM_MAX} {
      top: 64%;
      left: 9%;
    }
  }

  > img:nth-child(4) {
    top: 5%;
    right: 10%;
  }

  > img:nth-child(5) {
    top: 34%;
    left: -90px;
  }

  > img:nth-child(6) {
    top: 68%;
    right: 12%;
  }
`;

const OverviewImage = styled.img`
  max-width: 264px;
  position: absolute;
  z-index: -1;

  @media all ${screenSize.SMM_MAX} {
    display: none;
  }
`;

const OverviewBg = styled.img`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1665px;
  height: 1069px;
  z-index: -1;

  @media all ${screenSize.SMM_MAX} {
    display: none;
  }
`;

const OverviewBgMobile = styled.img`
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  height: 100%;

  @media all ${screenSize.SMM_MIN} {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media all ${screenSize.SMM_MAX} {
    font-size: 12px;
  }
`;

const VideoPlaceholder = styled.img`
  max-width: 605px;
  width: 100%;
  cursor: pointer;
`;

const VideoModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 51.2%;
  height: 0;
  overflow: hidden;
  width: 90vw;
`;

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const CTASection = styled(Section)`
  text-align: center;
  margin-bottom: 120px;
`;

const CTA = styled(Link)`
  button {
    min-width: 200px;
  }
`;

const { hero, overview, perks, faq, meta } = reactor;

export default () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <Wrapper title={meta.title} description={meta.description}>
      <Header />

      {showModal && (
        <VideoModal>
          <Backdrop onClick={closeModal} />
          <CloseBtn onClick={closeModal} src={CloseIcon} />
          <VideoContainer>
            <VideoFrame
              src="https://www.youtube.com/embed/QC_GZKb2X_U?autoplay=1&showinfo=0&modestbranding=1"
              frameBorder={0}
              allowFullScreen
              allow="autoplay; fullscreen"
            />
          </VideoContainer>
        </VideoModal>
      )}

      <HeroSection>
        <HeroContent>
          <H1>{hero.title}</H1>
          <HeroDescription>{hero.description}</HeroDescription>
          <Link href={hero.ctaLink} target="_blank">
            <Button title={hero.cta} onClick={() => {}} />
          </Link>
        </HeroContent>
        <div>
          <VideoPlaceholder src={VideoPlaceholderGif} onClick={openModal} />
        </div>
      </HeroSection>

      <OverviewSection>
        <TitleWrapper>
          <img src={Title1Icon} />
          <H2>{overview.title}</H2>
        </TitleWrapper>

        <OverviewWrapper>
          <OverviewItem indicator={1} content={overview.A} />
          <OverviewItem indicator={2} content={overview.B} />
          <OverviewItem indicator={3} content={overview.C} />

          <OverviewImage src={ReactorIllustration1} />

          <OverviewImage src={ReactorIllustration2} />

          <OverviewImage src={ReactorIllustration3} />

          <OverviewBgMobile src={LineMobile} />
          <OverviewBg src={LineDesktop} />
        </OverviewWrapper>
      </OverviewSection>

      <Section>
        <TitleWrapper>
          <img src={Title2Icon} />
          <H2>{perks.title}</H2>
        </TitleWrapper>

        <PerkList>
          <PerkItem
            img={TechnicalGuidanceIcon}
            title={perks.guidance.title}
            description={perks.guidance.description}
          />
          <PerkItem
            img={ProductIcon}
            title={perks.refinement.title}
            description={perks.refinement.description}
          />
          <PerkItem
            img={MarketingIcon}
            title={perks.marketing.title}
            description={perks.marketing.description}
          />
          <PerkItem
            img={GoToMarketIcon}
            title={perks.strategy.title}
            description={perks.strategy.description}
          />
          <PerkItem
            img={IntroductionsIcon}
            title={perks.introductions.title}
            description={perks.introductions.description}
          />
        </PerkList>
      </Section>

      <CTASection>
        <CTA href={hero.ctaLink} target="_blank">
          <Button title={hero.cta} onClick={() => {}} />
        </CTA>
      </CTASection>

      <Section>
        <H3>{faq.title}</H3>

        <FAQList>
          {faq.questions.map((item, index) => (
            <FAQItem
              key={index}
              question={item.title}
              answer={item.description}
            />
          ))}
        </FAQList>
      </Section>

      <Footer showSub={false} />
    </Wrapper>
  );
};
