import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  flex: 1;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked?: boolean; isCircle?: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.green : "#e0e8f8"};
  border-radius: ${({ isCircle }) => (isCircle ? "8px" : "2px")};
  transition: all 150ms;
  box-shadow: ${({ checked, theme }) =>
    !checked
      ? "inset 0 0 0 1px rgb(183, 194, 214)"
      : `inset 0 0 0 1px ${theme.colors.green}`};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px rgb(183, 194, 214);
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({ className, checked, isCircle, ...props }: any) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} isCircle={isCircle}>
      {!isCircle && (
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      )}
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
