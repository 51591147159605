import React, { FC } from "react";
import styled from "styled-components";
import { screenSize } from "../utils/theme";

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const OverviewContent = styled.p`
  font-size: 24px;
  margin: 0;

  span {
    background: white;

    span {
      color: #10bb34;
    }
  }

  @media all ${screenSize.SMM_MAX} {
    font-size: 12px;
    line-height: 18px;
  }
`;

const OverviewIndicator = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  background: #10bb34;
  color: white;
  flex-shrink: 0;

  @media all ${screenSize.SMM_MAX} {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
`;

interface OverviewItemProps {
  indicator: string | number;
  content: string;
}

const OverviewItem: FC<OverviewItemProps> = ({ indicator, content }) => (
  <OverviewWrapper>
    <OverviewIndicator>{indicator}</OverviewIndicator>
    <OverviewContent>
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </OverviewContent>
  </OverviewWrapper>
);

export default OverviewItem;
