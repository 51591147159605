import React from "react";
import styled from "styled-components";
import FXIcon from "../assets/svg/icon-fx.svg";
import { screenSize } from "../utils/theme";

interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  icon?: boolean;
  unit?: string;
  value?: string;
  placeholder?: string;
  titleAlign?: "left" | "center";
  headerStyle?: "transparent" | "translucent" | "opaque";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  editable?: boolean;
  shouldBlink?: boolean;
}

const headerStyles = {
  transparent: "none",
  translucent: "rgba(16,187,52,0.15)",
  opaque: "#196137",
};

const InputBox: React.FC<Props> = (props) => {
  const {
    onChange,
    title,
    subtitle,
    unit,
    icon = false,
    value,
    placeholder,
    headerStyle = "translucent",
    className,
    titleAlign = "left",
    editable = false,
    shouldBlink = false,
  } = props;

  return (
    <StyledInputBox
      className={className}
      headerColor={headerStyles[headerStyle]}
    >
      <div className="top">
        <p className={"title"} style={{ textAlign: titleAlign }}>
          {title}
        </p>
        <p className={"subtitle"}>
          {!!icon && <img src={FXIcon} alt="fx" />}
          {subtitle}
        </p>
      </div>
      <div className="bottom">
        <div className="inner">
          <div className="cursor">
            <StyledInput
              onChange={onChange}
              placeholder={placeholder ?? "_"}
              centerAlign={!unit}
              value={value}
              readOnly={!editable}
            />
            {shouldBlink && <i></i>}
          </div>
          {!!unit && <p>{unit}</p>}
        </div>
      </div>
    </StyledInputBox>
  );
};

const StyledInput = styled.input<{ centerAlign: boolean }>`
  text-align: ${({ centerAlign }) => (centerAlign ? "center" : "left")};
  font-size: 1.5rem;

  @media all ${screenSize.M} {
    font-size: 1.25rem;
  }
`;

const StyledInputBox = styled.div<{ headerColor: string }>`
  flex-direction: column;
  width: 100%;

  p {
    margin: 0.75rem 0;
  }

  p.title {
    font-family: "AndaleMono", Courier New, Courier, monospace;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  p.subtitle {
    font-size: 1rem;
    color: #10bb34;
    margin-top: 0;
    display: flex;
    align-items: center;
    font-family: "AndaleMono", Courier New, Courier, monospace;
    white-space: nowrap;
  }

  p.subtitle img {
    margin-right: 0.25rem;
  }

  div.top {
    border-radius: 4px;
    border: 1px solid #10bb34;
    background: ${({ headerColor }) => headerColor};
  }

  div.top p {
    line-height: 1.6rem;
  }

  div.bottom {
    border: 1px solid #10bb34;
    border-top-width: 0;
    margin-top: -4px;
    padding-top: 0.25rem;
  }

  div.bottom .inner {
    display: flex;
    flex: 1;
    min-height: 3rem;
    height: 100%;
    width: 100%;
    justify-content: space-between;

    input {
      font-family: "AndaleMono", Courier New, Courier, monospace;
      width: 100%;
      padding: 0 1rem 0;
      color: #10bb34;
    }

    input::placeholder {
      color: #10bb34;
    }

    p {
      padding: 0 1rem;
      color: #10bb34;
      white-space: nowrap;
      font-size: 0.75rem;
      text-align: right;
    }
  }

  div.cursor {
    position: relative;
    display: flex;
    width: 100%;
  }

  div.cursor i {
    position: absolute;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.green};
    left: 0.5rem;
    top: 0.5rem;
    bottom: 0.75rem;
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 1, 1);
    opacity: 1;
  }

  .cursor input:focus + i {
    display: none;
  }

  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media all ${screenSize.M} {
    p.title {
      font-size: 1rem;
    }
    p.subtitle {
      font-size: 0.875rem;
    }
  }
`;

export default InputBox;
