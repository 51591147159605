import { useEffect, useState } from "react";

export const useResize = (myRef: any) => {
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);

  const handleResize = () => {
    setWidth(myRef.current?.offsetWidth ?? 0);
    setHeight(myRef.current?.offsetHeight ?? 0);
  };

  useEffect(() => {
    setWidth(myRef.current?.clientWidth ?? 0);
    setHeight(myRef.current?.clientHeight ?? 0);
  }, [myRef]);

  useEffect(() => {
    myRef.current && window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return { width, height };
};
