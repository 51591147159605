import styled from "styled-components";
import React from "react";
import Logo from "../../assets/svg/logo.svg";
import { about } from "../../assets/strings";
import TwitterIconAlt from "../../assets/svg/icon-twitter-alt.svg";
import { screenSize } from "../../utils/theme";
import Link from "../../components/elements/Link";

const { branding: root } = about;

const BrandSection: React.FC = () => {
  return (
    <StyledBrandSection>
      {/*<a className={"logo"} href="https://superfluid.finance">
        <img src={Logo} alt={root.logoAltTitle} />
      </a>*/}
      <h3>{root.title}</h3>
      <StyledTwitter
        text={"twitter"}
        href="https://twitter.com/intent/follow?screen_name=Superfluid_HQ"
        target="_blank"
      >
        <img src={TwitterIconAlt} alt={"twitter icon"} />
        <p>{root.twitter}</p>
      </StyledTwitter>
    </StyledBrandSection>
  );
};

const StyledTwitter = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  :hover,
  :active,
  :visited {
    text-decoration: underline;
    cursor: pointer;
  }

  img {
    width: 18px;
    margin: 0 0.25rem 0 0;
  }
`;

const StyledBrandSection = styled.div`
  grid-column: 2/16;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;

  .logo {
    width: 200px;
    margin: 3.5rem 0 0 0;
  }

  .logo:hover {
    opacity: 0.75;
  }

  h3 {
    font-weight: normal;
    line-height: 1.625rem;
    text-align: center;
    margin: 4.5rem 0 0 0;
  }

  p {
    margin: 0;
  }

  @media all ${screenSize.L_MIN} {
    h3 {
      width: 50%;
    }
  }
`;

export default BrandSection;
