import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ModalType } from "../../../context/modalContext";
import DataCaptureCard from "../DataCaptureCard";
import { track } from "../../../utils/user";

const UserCaptureModal: React.FC = () => {
  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "illustration-data-modal-user.png" }
      ) {
        ...modalIllustration
      }
    }
  `);

  const handleOnClick = (d?: any, index?: number) => {
    track("Form Submitted", { UserSegment: "web3user" });
  };

  return (
    <DataCaptureCard
      modalType={ModalType.UserCapture}
      image={illustration.childImageSharp.fluid}
      onClick={handleOnClick}
    />
  );
};

export default UserCaptureModal;
