import React from "react";
import styled from "styled-components";
import TopLeftIcon from "../assets/svg/icon-top-left-corner.svg";
import BottomRightIcon from "../assets/svg/icon-bottom-right-corner.svg";

const QuoteBox: React.FC = ({ children }) => {
  return (
    <StyledQuoteBox>
      <div className="top-left">
        <img src={TopLeftIcon} />
      </div>
      {children}
      <div className="bottom-right">
        <img src={BottomRightIcon} />
      </div>
    </StyledQuoteBox>
  );
};

const StyledQuoteBox = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  div.bottom-right {
    display: flex;
    justify-content: flex-end;
  }

  p {
    margin: 0.5rem 0;
    line-height: 1.6rem;
  }
`;

export default QuoteBox;
