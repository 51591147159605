import React, { useEffect, useRef } from "react";

import { pages } from "../pageConfig"
import { Router } from "@reach/router"
import RPCHelper from "../components/pages/RPCHelper"
import About from "../components/pages/About"
import Home from "../components/pages/Home"
import TermsOfUse from "../components/pages/TermsOfUse"
import Reactor from "../components/pages/Reactor";

export default () => {
  useEffect(() => {
    if (window.location.pathname === "/") {
      // go to home page
      window.location.href = "/home"
    }
  }, []);

  return (
    // NOTE!
    // For some reason, if root path has a component, it screws up the other
    // pages, so the home component is forced to be at /home for now...
    // ^
    // This is probably due to the fact that "/" matches every route. 
    // This could be solved by adding Switch around routes so only one (first match) route will be rendered OR add "exact" prop to the route so "/page" won't also match "/page/subpage" etc.
    // In general it would be good to add both these solutions.
    <div>
      <Router>
        <About path={ pages.about } />
        <RPCHelper path={ pages.rpc } />
        <TermsOfUse path={ pages.termsofuse } />
        <Home path={ pages.home } />
        <Reactor path={ pages.reactor } />
      </Router>
    </div>
  )
}
