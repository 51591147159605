import styled from "styled-components";
import { DotGroup } from "pure-react-carousel";
import { screenSize } from "../../utils/theme";

export const Desktop = styled.div`
  display: none;

  @media all ${screenSize.L_MIN} {
    ${({ theme }) => theme.mainGrid};

    min-height: inherit !important;
    display: grid;
    justify-content: space-between;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    align-content: center;
  }
`;

export const Mobile = styled.div`
  display: block;

  @media all ${screenSize.L_MIN} {
    display: none;
  }
`;

export const Dots = styled(DotGroup)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;

  button {
    border-radius: 2px;
    background: rgb(188, 197, 211);
    height: 0.5rem;
    width: 3.5rem;
    margin: 0 0.25rem;
    transition: background ease-in-out 0.25s;
  }

  .carousel__dot--selected {
    background: ${({ theme }) => theme.colors.green};
  }
`;
