import React, { MutableRefObject, forwardRef, ReactNode } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme, GlobalStyle, screenSize } from "../utils/theme";
import SEO from "./SEO";
import ModalProvider from "../context/modalContext";
import Modal from "./Modal";

import "../assets/styles/fonts.css";
import "../assets/styles/text.css";

interface Props {
  title: string;
  description: string;
  className?: string;
  children?: ReactNode;
  ref?: MutableRefObject<HTMLDivElement>;
}

const InnerWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(16, 1fr);
`;

const Wrapper = React.forwardRef(
  (props: Props, ref: MutableRefObject<HTMLDivElement>) => {
    const { title, description, children, className } = props;

    return (
      <>
        <SEO description={description} title={title} />
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <GlobalStyle />
            <Modal />
            <InnerWrapper className={className} ref={ref}>
              {children}
            </InnerWrapper>
          </ModalProvider>
        </ThemeProvider>
      </>
    );
  }
);

export default Wrapper;
