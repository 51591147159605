import styled from "styled-components";
import { screenSize } from "../../utils/theme";
import RealTimeBalance from "../../components/RealTimeBalance";

export const Wrapper = styled.div`
  border-radius: 4px;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.text {
    font-size: 0.9em;
  }
`;

export const DashedBox = styled.div`
  border-radius: 4px;
  border: 2px solid rgb(27, 132, 71, 0.5);
  border-style: dashed;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  p {
    width: 100%;
  }

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
    padding: 0;
    border: none;
    width: 100%;
  }
`;

export const PaddingBox = styled.div`
  width: 100%;

  @media all ${screenSize.L_MIN} {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
`;

export const MiddleBox = styled.div`
  @media all ${screenSize.L_MIN} {
    display: flex;
    flex-basis: 25%;
    justify-content: center;
  }
`;

export const Entries = styled.div`
  border-radius: 4px;
  border: 2px solid rgb(27, 132, 71, 0.5);
  border-style: dashed;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  p {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 1em;
  }
`;

export const TopRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;

  @media all ${screenSize.L_MIN} {
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;

    > div {
      flex-grow: 0;
      flex-basis: 10%;
    }

    > div.balance {
      flex-basis: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    > ${DashedBox} {
      flex-basis: 100%;
      flex-grow: 0;
      display: flex;
    }
  }
`;

export const MiddleRow = styled(DashedBox)`
  .boxes {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media all ${screenSize.L_MIN} {
    align-items: flex-start;
    flex-direction: row-reverse;

    > div {
      flex-grow: 0;
      flex-basis: 10%;
    }

    > div.text {
      flex-basis: 50%;
      flex-grow: 0;

      p {
        padding: 0;
        box-sizing: border-box;
        margin-block-start: 0;
        margin-block-end: 0;
      }
      p:nth-child(n + 2) {
        margin-block-start: 0.5em;
      }
    }

    > div.boxes {
      flex-direction: row;
      flex-basis: 100%;
    }
  }
`;

export const BottomRow = styled(PaddingBox)`
  display: flex;
  flex-direction: column;

  @media all ${screenSize.L_MIN} {
    align-items: flex-start;
    flex-direction: row;
    position: relative;

    &::before {
      content: "👉";
      position: absolute;
      top: 42%;
      left: -3rem;
      font-size: 1.75rem;
    }

    > div {
      flex-grow: 0;
      flex-basis: 10%;
    }

    .text {
      flex-basis: 50%;
      flex-grow: 0;

      p {
        padding: 0;
        box-sizing: border-box;
        margin-block-start: 0;
        margin-block-end: 0;
      }
      p:nth-child(n + 2) {
        margin-block-start: 0.5em;
      }
    }

    > div.boxes .flow {
      flex-basis: 50%;
    }

    > div.boxes {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      width: 100%;
    }

    ${MiddleBox} {
      flex-basis: 12.5%;
    }

    ${Entries} {
      flex-basis: 50%;
    }
  }
`;

export const VerticalArrow = styled.img`
  height: 2rem;

  @media all ${screenSize.L_MIN} {
    display: none;
  }
`;

export const HorizontalArrow = styled.img`
  display: none;

  @media all ${screenSize.L_MIN} {
    display: inline;
    margin-right: 1rem;
  }
`;

export const Quote = styled.p`
  font-size: 1.5rem;
  color: white;
  line-height: 2rem !important;
  font-weight: normal;
`;

export const IconImage = styled.img`
  width: 1rem;
  height: auto;
  margin: 1rem 0;

  @media all ${screenSize.L_MIN} {
    margin: 0 1rem;
  }
`;

export const FlowButton = styled.button`
  background-color: #8392ad;
  border-radius: 4px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
  }
`;
