import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const CurveTop = styled.img`
  grid-column: 1/17;
  width: 100%;
  /*margin-top: 6.25rem;*/
`;

export const CurveBottom = styled.div`
  grid-column: 1/17;
  width: 100%;
  z-index: 2;

  .container {
    overflow: hidden;

    > div {
      margin-left: -50% !important;
      width: 250% !important;
      margin-bottom: -3em;
    }

    @media all ${screenSize.M_MIN} {
      > div {
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
  }
`;

export const CurveBuffer = styled.div`
  height: 1rem;
  grid-column: 1/17;
  background: white;
  margin-top: -0.75rem;
`;
