import React from "react";

import TickIcon from "../../assets/svg/icon-tick-box.svg";
import { home } from "../../assets/strings";
import * as S from "./ProtocolSectionPageB.style";
import ProtocolSectionBase from "./ProtocolSectionBase";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { ProtocolSectionProps as Props } from "../../types";

const { protocolSection: root } = home;

const ProtocolSectionPageB: React.FC<Props> = ({ onSetIndex, index }) => {
  const slide = root.slides["B"];

  const mappedDescriptions = slide.descriptions.map((str) => (
    <p dangerouslySetInnerHTML={{ __html: str }} />
  ));

  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(relativePath: { eq: "illustration-token.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Wrapper>
        <S.LeftSide>
          <S.Title>{slide.title}</S.Title>
          <Img
            fadeIn={false}
            loading={"eager"}
            fluid={illustration.childImageSharp.fluid}
          />
        </S.LeftSide>
        <S.HorizontalSpacer />
        <S.RightSide>
          <S.SecondaryBox>
            <div className="icon">
              <img src={TickIcon} />
            </div>
            {mappedDescriptions[0]}
          </S.SecondaryBox>
          <S.VerticalSpacer />
          <S.SecondaryBox>
            <div className="icon">
              <img src={TickIcon} />
            </div>
            {mappedDescriptions[1]}
          </S.SecondaryBox>
          <S.VerticalSpacer />
          <S.SecondaryBox>
            <div className="icon">
              <img src={TickIcon} />
            </div>
            {mappedDescriptions[2]}
          </S.SecondaryBox>
        </S.RightSide>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageB;
