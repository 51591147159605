import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Title = styled.h3`
  grid-column: 3/15;
`;

export const Wrapper = styled.div`
  grid-column: 3/15;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
  }
`;

export const SecondaryBox = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  p {
    padding: 0 0 0 1.5rem;
  }

  img {
    width: 1.5rem;
  }

  .icon {
    margin: 0.25rem 0;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;

  div {
    margin-right: 4rem;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 96px;

  @media all ${screenSize.L_MIN} {
    align-items: flex-end;
  }
`;

export const VerticalSpacer = styled.div`
  height: 4.5rem;
`;

export const HorizontalSpacer = styled.div`
  width: 7.25rem;
`;
