import styled, { css } from "styled-components";
import { screenSize } from "../../utils/theme";

const defaultBox = css`
  width: 1.625rem;
  height: 0.4rem;
  box-sizing: border-box;
  margin: 0 0.1rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.green};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 0 1rem;
  margin: 1rem 0;

  @media all ${screenSize.L_MIN} {
    padding: 0;
  }
`;

export const InactiveBox = styled.button`
  ${defaultBox};

  border: 1px solid ${({ theme }) => theme.colors.green};
  opacity: 0.5;
`;

export const ActiveBox = styled.button`
  ${defaultBox};

  background: ${({ theme }) => theme.colors.green};
`;
