import React, { ReactNode } from "react";

import SingleCubeIcon from "../../assets/svg/icon-cube-0.svg";
import DoubleCubeIcon from "../../assets/svg/icon-cube-1.svg";
import { home } from "../../assets/strings";
import * as S from "./ProtocolSectionPageA.style";
import ProtocolSectionBase from "./ProtocolSectionBase";
import QuoteBox from "../../components/QuoteBox";
import IconBox from "../../components/IconBox";
import { ProtocolSectionProps as Props } from "../../types";

const { protocolSection: root } = home;

const ProtocolSectionPageA: React.FC<Props> = ({ index, onSetIndex }) => {
  const slide = root.slides["A"];

  const mappedDescriptions = slide.descriptions.map((str) => (
    <p dangerouslySetInnerHTML={{ __html: str }} />
  ));

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Title>{slide.title}</S.Title>
      <S.Wrapper>
        <S.LeftSide>
          <QuoteBox>{mappedDescriptions[0]}</QuoteBox>
        </S.LeftSide>
        <S.HorizontalSpacer />
        <S.RightSide>
          <IconBox image={SingleCubeIcon}>{mappedDescriptions[1]}</IconBox>
          <S.VerticalSpacer />
          <IconBox image={DoubleCubeIcon}>{mappedDescriptions[2]}</IconBox>
        </S.RightSide>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageA;
