import React, { useRef } from "react";
import styled from "styled-components";

import Wrapper from "../Wrapper";
import Button from "../elements/Button";
import Header from "../../sections/header/Header";
import Footer from "../../sections/footer/Footer";
import BackgroundTop from "../../assets/svg/bg-about-top.svg";
import BackgroundMiddle from "../../assets/svg/bg-about-middle.svg";
import { home } from "../../assets/strings";
import { screenSize } from "../../utils/theme";

const { meta } = home;

export default () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledWrapper
      title={meta.title}
      description={meta.description}
      ref={wrapperRef}
    >
      <Header />
      <PageTitle>Add Superfluid Endpoints to Metamask</PageTitle>
      <AddNetworkButton title="Matic" onClick={() => addNetwork("matic")}></AddNetworkButton>
      <AddNetworkButton title="xDAI" onClick={() => addNetwork("xdai")}></AddNetworkButton>
      <Footer />
    </StyledWrapper>
  );
};

const RPC_ENDPOINTS = {
  xdai: [{
    chainId: "0x64",
    chainName: "xDAI (Superfluid)",
    nativeCurrency:
    {
      name: "xDAI",
      symbol: "xDAI",
      decimals: 18
    },
    rpcUrls: ["https://rpc-endpoints.superfluid.dev/xdai"],
    blockExplorerUrls: ["https://blockscout.com/poa/xdai/"],
  }],
  matic: [{
    chainId: "0x89",
    chainName: "Matic (Superfluid)",
    nativeCurrency:
    {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc-endpoints.superfluid.dev/matic"],
    blockExplorerUrls: ["https://explorer-mainnet.maticvigil.com/"],
  }],
};

async function addNetwork(network) {
  let ethereum = window.ethereum;

  /* eslint-disable */
  const tx = await ethereum.request({
    method: "wallet_addEthereumChain",
    params: RPC_ENDPOINTS[network]
  }).catch()
  if (tx) {
    console.log(tx)
  }
}

const PageTitle = styled.h2`
  text-align: center;
  grid-column: 1/17;
`;

const AddNetworkButton = styled(Button)`
  text-align: center;
  grid-column: 8/17;
  width: 25%;
  margin-bottom: 1.25rem;

  @media all ${screenSize.SMM_MAX} {
    text-align: center;
  grid-column: 2/16;
  width: 100%;
  margin-bottom: 1.25rem;
  }
  @media all ${screenSize.M} {
    text-align: center;
  grid-column: 8/16;
  width: 50%;
  margin-bottom: 1.25rem;
  }
`;

const StyledWrapper = styled(Wrapper)`
  background: url(${BackgroundTop}), url(${BackgroundMiddle});
  background-repeat: no-repeat, no-repeat;
  background-size: 18rem auto, 10rem auto;
  background-position-y: 4rem, 45rem;
  background-position-x: 0, 100%;

  @media all ${screenSize.M} {
    background-size: 20rem auto, 10rem auto;
    background-position-y: 0, 40rem;
    background-position-x: 0, 100%;
  }

  @media all ${screenSize.L_MIN} {
    background-size: 30rem auto, 20rem auto;
    background-position-y: -10rem, 20rem;
    background-position-x: 0, 100%;
  }
`;
