import React from "react";
import { home } from "../../assets/strings";
import ArrowIcon from "../../assets/svg/icon-arrow-vertical.svg";
import PlusIcon from "../../assets/svg/icon-plus.svg";
import InputBox from "../../components/InputBox";
import RealTimeBalance from "../../components/RealTimeBalance";
import StackedBox from "../../components/StackedBox";
import useDimensions from "../../hooks/useDimensions";
import { ProtocolSectionProps as BaseProps } from "../../types";
import { breakPoints } from "../../utils/theme";
import ProtocolSectionBase from "./ProtocolSectionBase";
import * as S from "./ProtocolSectionPageE.style";

const { protocolSection: root } = home;

interface Props extends BaseProps {
  onLearnMoreClick: (destination: number) => void;
}

const ProtocolSectionPageE: React.FC<Props> = (props) => {
  const {
    onSetIndex,
    index,
    onLearnMoreClick,
    data: {
      balance,
      netFlowRate,
      timeElapsed,
      flowAgreement,
      initialAgreement,
      staticBalance,
    },
  } = props;

  const slide = root.slides["E"];

  const { width } = useDimensions();

  const isMobile = width < breakPoints.md;

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <RealTimeBalance
        isMobile={isMobile}
        value={balance + initialAgreement}
        timer={timeElapsed}
      />
      <S.VerticalArrow src={ArrowIcon} alt="plus icon" />
      <S.BoxContainer>
        <S.InputWrapper>
          <InputBox
            title={slide.boxes[0].title}
            subtitle={slide.boxes[0].subtitle}
            unit={slide.boxes[0].unit}
            icon={true}
            value={`${netFlowRate}`}
          />
          <span>
            👉 <a onClick={() => onLearnMoreClick(5)}>Learn more</a>
          </span>
        </S.InputWrapper>
        <S.PlusImage src={PlusIcon} alt="plus icon" />
        <S.InputWrapper>
          <InputBox
            title={slide.boxes[1].title}
            subtitle={slide.boxes[1].subtitle}
            unit={slide.boxes[1].unit}
            icon={true}
            value={`${Math.ceil(initialAgreement)}`}
          />
          <span>
            👉 <a onClick={() => onLearnMoreClick(6)}>Learn more</a>
          </span>
        </S.InputWrapper>
        <S.PlusImage src={PlusIcon} alt="plus icon" />
        <InputBox title={slide.boxes[2].title} value={`${staticBalance}`} />
        <S.PlusImage src={PlusIcon} alt="plus icon" />
        <StackedBox title={slide.boxes[3].title} />
      </S.BoxContainer>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageE;
