import styled from "styled-components";
import MobileBackgroundTop from "../../assets/svg/bg-data-top-mobile.svg";
import MobileBackgroundBottom from "../../assets/svg/bg-data-bottom-mobile.svg";
import BackgroundTopRight from "../../assets/svg/bg-data-top-right.svg";
import BButton from "../elements/Button";
import Input from "../elements/Input";
import { screenSize } from "../../utils/theme";
import GatsbyImage from "gatsby-image";

export const Wrapper = styled.div`
  ${({ theme }) => theme.mainGrid};

  min-height: inherit !important;
  display: flex;
  background: url(${MobileBackgroundTop}), url(${MobileBackgroundBottom}) #fff;
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 9.5rem, 9rem;
  border-radius: 0.25rem;
  min-height: 17.5rem;
  width: 16.75rem;
  align-self: center;
  padding: 0 1rem;
  box-sizing: border-box;
  justify-content: space-between;

  h2,
  p {
    margin: 0;
  }

  input {
    margin: 1.25rem 0 0 0;
    width: 100%;
  }

  button {
    margin: 1.25rem 0;
  }

  @media all ${screenSize.L_MIN} {
    max-width: 1024px !important;
    border-radius: 0.5rem;

    background: url(${MobileBackgroundTop}), url(${MobileBackgroundBottom}),
      url(${BackgroundTopRight}) #fff;
    background-position: left top, right bottom, right top;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 9.5rem, 9rem, 15rem;
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const LeftContainer = styled.div`
  display: none;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 1rem;

  @media all ${screenSize.L_MIN} {
    display: flex;
    min-width: 18rem;
    flex: 1;
    margin-right: 4rem;
  }

  p {
    margin: 0.5rem 0 2.25rem 0;
    line-height: 1.5rem;
  }
`;

export const Image = styled(GatsbyImage)<{ maxWidth: number }>`
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}rem`};
`;

export const RightContainer = styled.div`
  margin-top: 2.375rem;
  flex: 5;
  display: flex;
  flex-direction: column;

  @media all ${screenSize.L_MIN} {
    input {
      max-width: 20rem;
    }
  }
`;

export const ErrorMessage = styled.p<{ isHidden: boolean }>`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.inputError};
  margin: 0.5rem 0 !important;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`;

export const Spacer = styled.div`
  height: 2.375rem;
  width: 100%;
`;

export const ListSelection = styled.div`
  display: flex;
`;

export const SelectInput = styled(Input)`
  margin: 0 !important;
`;

export const CheckboxLabel = styled.label`
  display: block;
  margin: 1rem 0;

  span {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.checkboxTextColor};
  }
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Button = styled(BButton)`
  width: 100%;
  max-width: 8.75rem;
`;
