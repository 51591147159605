import styled from "styled-components";
import React from "react";
import { screenSize } from "../../utils/theme";
import { track } from "../../utils/user";

interface Props {
  className?: string;
  title: string;
  disabled?: boolean;
  onClick: (e: any) => void;
  context?: string;
}

const Button: React.FC<Props> = (props: Props) => {
  const { title, className, disabled, onClick, context } = props;

  const handleClick = () => {
    onClick();
    if (!context) return;
    track("Button Clicked", {
      buttonName: title,
      context: context,
    });
  };

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background: ${({ theme }) => theme.buttonDefault};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  transition: background ease-in 0.25s;
  font-size: 1.125rem;
  padding: 0.75rem 1.375rem;
  border-radius: 0.25rem;

  :hover {
    background: ${({ theme }) => theme.buttonHover};
  }

  :active {
    background: ${({ theme }) => theme.buttonActive};
  }

  :disabled {
    background: ${({ theme }) => theme.buttonDisabled};
  }

  @media all ${screenSize.S_MIN} {
    font-size: 0.9rem;
  }
`;

export default Button;
