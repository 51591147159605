import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Wrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  grid-column: 1/17;
  overflow: hidden;
  z-index: -1;
  margin-top: -12rem;
  margin-left: 0;

  @media all ${screenSize.M} {
    margin-left: -35%;
    margin-top: -8rem;
  }

  @media all ${screenSize.SMM_MAX} {
    margin-left: -50%;
  }

  @media all ${screenSize.L_MIN} {
    margin-top: -12rem;
    margin-bottom: 5rem;
  }
`;
