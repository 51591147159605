import React from "react";
import styled from "styled-components";
import { screenSize } from "../../utils/theme";

interface Props {
  middleLineXPos?: number;
}

const HorizontalLine: React.FC<Props> = ({ middleLineXPos }) => (
  <StyledHorizontalLine middleLineXPos={middleLineXPos}>
    <div className="spacer" />
    <div className="line" />
    <div className="spacer" />
  </StyledHorizontalLine>
);

export const StyledHorizontalLine = styled.div<{ middleLineXPos?: number }>`
  display: none;

  @media all ${screenSize.L_MIN} {
    position: relative;
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: column;

    > div.spacer {
      flex: 1;
    }

    > div.line {
      height: 1px;
      width: 100%;
      border-top: 1px dashed rgb(27, 132, 71, 0.5);
    }

    &:before {
      display: ${({ middleLineXPos }) => !middleLineXPos && "none"};
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${({ middleLineXPos }) =>
        !!middleLineXPos && `calc(${middleLineXPos}% - 5px)`};
      width: 10px;
      border-left: 2px solid ${({ theme }) => theme.colors.green};
      border-right: 2px solid ${({ theme }) => theme.colors.green};
      z-index: 2;
    }
  }
`;

export default HorizontalLine;
