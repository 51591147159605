import styled from "styled-components";
import { screenSize } from "../utils/theme";
import GatsbyImage from "gatsby-image";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  max-height: 550px;

  button {
    margin: 1.25rem 1rem;
    width: calc(100% - 40px);
  }
`;

export const Container = styled.div`
  text-align: center;

  @media all ${screenSize.L_MIN} {
    padding: 5%;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  flex: 1;
`;

export const Logo = styled.img`
  @media all ${screenSize.S_MAX} {
    width: 10rem;
  }
`;
