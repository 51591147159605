import React, { Dispatch, useContext, useState } from "react";

export enum ModalType {
  GetStarted = "getStarted",
  MerchantCapture = "merchantCapture",
  MerchantEmailCapture = "merchantEmailCapture",
  UserCapture = "userCapture",
  DeveloperCapture = "developerCapture",
  InvestorCapture = "investorCapture",
  LinkGeneration = "linkGeneration",
}

type ModalProps = { type: ModalType; data?: any } | undefined;

type ContextType = [ModalProps, Dispatch<ModalProps>];

export const ModalContext = React.createContext<ContextType>([
  undefined,
  () => {},
]);

export default function ModalProvider({ children }: any) {
  const [state, setState] = useState<ModalProps>();

  return (
    <ModalContext.Provider value={[state, setState]}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}
