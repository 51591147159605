import React from "react";

import { home } from "../../assets/strings";
import * as S from "./ProtocolSectionPageF.style";
import ProtocolSectionBase from "./ProtocolSectionBase";
import useDimensions from "../../hooks/useDimensions";
import { breakPoints } from "../../utils/theme";
import InputBox from "../../components/InputBox";
import InOutBoxIcon from "../../assets/svg/icon-in-out-box.svg";
import InfBoxIcon from "../../assets/svg/icon-infinite-box.svg";
import QuoteBox from "../../components/QuoteBox";
import IconBox from "../../components/IconBox";
import { ProtocolSectionProps as Props } from "../../types";

const { protocolSection: root } = home;

const ProtocolSectionPageF: React.FC<Props> = ({
  onSetIndex,
  index,
  data: { netFlowRate },
}) => {
  const slide = root.slides["F"];

  const { width } = useDimensions();

  const isMobile = width < breakPoints.md;

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Wrapper>
        <InputBox
          title={"Constant Flow Agreement"}
          subtitle={"rate * time"}
          unit={"/ second"}
          icon={true}
          value={`${netFlowRate}`}
        />
        <S.VerticalSpacer />
        <S.DesktopWrapper>
          <QuoteBox>
            <S.Quote>
              A constant flow agreement is a stream of money that is invariable
              over time.
            </S.Quote>
          </QuoteBox>
          <S.VerticalSpacer />
          <IconBox image={InOutBoxIcon}>
            <p>
              Users can have inflows (streams coming in) and outflows (streams
              going out) from the same account, and their <span>net flow</span>{" "}
              is the difference between incoming and outgoing
            </p>
          </IconBox>
          <S.VerticalSpacer />
          <IconBox image={InfBoxIcon}>
            <p>
              There is no limit to the number of flows an account can handle!
            </p>
          </IconBox>
        </S.DesktopWrapper>
      </S.Wrapper>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageF;
