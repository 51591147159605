import styled from "styled-components";
import { screenSize } from "../../utils/theme";

export const Wrapper = styled.div`
  ${({ theme }) => theme.mainGridWithPadding};

  flex-direction: column;
  padding: 0 3rem;
  min-height: inherit !important;
`;

export const Logo = styled.img`
  @media all ${screenSize.S_MAX} {
    width: 10rem;
  }
`;

export const MobileConnect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    flex: 1;
    text-align: center;
    font-size: 1.2em;
  }

  @media all ${screenSize.M_} {
    align-items: left;
  }
`;

export const MobileConnectHead = styled.div`
  p {
    line-height: 1.75rem;
  }
`;

export const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 12em;

  div {
    display: flex;
    flex-direction: column;
  }

  button {
    flex: 1;
  }

  input {
    flex: 2;
    margin-bottom: 0.5rem;
  }

  @media all ${screenSize.S_MIN} {
    max-width: 25rem;
    justify-content: stretch;

    div {
      flex-direction: row;
    }

    input {
      margin-right: 0.5rem;
      margin-bottom: 0;
      padding: 0.75rem 0.5rem;
    }
  }
`;

export const Message = styled.p<{ error: boolean }>`
  font-size: 0.66rem;
  text-align: left;
  transition: all ease-in-out 0.25s;
  color: ${({ error, theme }) =>
    error ? theme.inputError : theme.colors.grey};
`;

export const Capture = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  justify-content: flex-end;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;

    input {
      margin-right: 0.5rem;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 2.5rem 0;

  @media all ${screenSize.L_MIN} {
    flex-direction: row;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
  }

  a {
    display: block;
    margin-block-start: 1em;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const Section = styled.div`
  margin: 1.5rem 0;

  span {
    opacity: 0.5;
  }
`;

export const Copyright = styled.p`
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.75rem;

  a {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;
