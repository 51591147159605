import React, { FC, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const AccordionWrapper = styled.div<{ height?: number; open?: boolean }>`
  transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1);
  height: ${({ height }) => `${height}px`};
  transform: translateY(${({ open }) => (open ? "0px" : "-10px")});

  ${({ open }) =>
    open
      ? css`
          transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1),
            opacity 300ms cubic-bezier(0.55, 0, 0.1, 1) 50ms,
            transform 300ms cubic-bezier(0.55, 0, 0.1, 1) 50ms;
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

interface AccordionProps {
  open?: boolean;
}

const Accordion: FC<AccordionProps> = ({ open, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    setHeight(open ? ref.current.scrollHeight : 0);
  }, [open]);

  return (
    <AccordionWrapper ref={ref} height={height} open={open}>
      {children}
    </AccordionWrapper>
  );
};

export default Accordion;
