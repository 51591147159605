import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import LinesSVG from "../../assets/svg/bg-about-team.svg";
import { screenSize } from "../../utils/theme";
import BaseLink from "../../components/elements/Link";

export const Wrapper = styled.div`
  ${({ theme }) => theme.mainPadding};

  align-items: center;
  justify-content: center;
  grid-column: 1/17;
  overflow: hidden;
  background-image: url(${LinesSVG});
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(16, 1fr);
`;

export const Image = styled(BackgroundImage)`
  ${({ theme }) => theme.mainGrid};

  padding: 15vw 1.5rem;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  @media all ${screenSize.L_MIN} {
    border-radius: 1rem;
    overflow: hidden;
  }

  p {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.5em;
    padding: 0 2rem 0 2rem;
  }
`;

export const Link = styled(BaseLink)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1.5rem;

  :hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
    text-shadow: none;
  }

  :active {
    background: rgba(255, 255, 255, 0.9);
  }
`;
