import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { ModalType } from "../../../context/modalContext";
import post from "../../../utils/post";
import { track } from "../../../utils/user";
import DataCaptureCard, { InputProp, ValidationType } from "../DataCaptureCard";

const InvestorCaptureModal: React.FC = () => {
  const [data, setData] = useState<{
    emailAddress: string;
    investmentTitle: string;
    investmentStage: string[];
    investmentType: string;
    investmentSize: string;
  }>();

  const [isComplete, setComplete] = useState(false);

  useEffect(() => {
    if (!isComplete) return;

    // send form data.
    track("Form Submitted", { UserSegment: "investor", data });
  }, [data]);

  const inputProps: { [index: string]: InputProp } = {
    0: { validation: ValidationType.EMAIL },
  };

  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "illustration-data-modal-developer.png" }
      ) {
        ...modalIllustration
      }
    }
  `);

  const handleOnClick = (d?: any, index?: number) => {
    if (!d) return;

    const { inputField, singleSelect, multiSelect } = d;

    // Each case is the return blob of data
    // send POST either within each case or with final data blob.
    switch (index) {
      case 0:
        const emailAddress = inputField as string;
        setData({ ...data, emailAddress });
        post({ email: emailAddress, investor: true });
        break;
      case 1:
        const investmentTitle = singleSelect as string;
        setData({ ...data, investmentTitle });
        post({ investmentTitle });
        break;
      case 2:
        const investmentStage = multiSelect as string[];
        setData({ ...data, investmentStage });
        post({ investmentStage });
        break;
      case 3:
        const investmentType = singleSelect as string;
        setData({ ...data, investmentType });
        post({ investmentType });
        break;
      case 4:
        const investmentSize = singleSelect as string;
        setComplete(true);
        setData({ ...data, investmentSize });
        post({ investmentSize });
        break;
    }
  };

  return (
    <DataCaptureCard
      modalType={ModalType.InvestorCapture}
      inputProps={inputProps}
      image={illustration.childImageSharp.fluid}
      onClick={handleOnClick}
    />
  );
};

export default InvestorCaptureModal;
