import React from "react";
import * as S from "./JoinTeamSection.style";
import { about } from "../../assets/strings";
import { graphql, useStaticQuery } from "gatsby";

const { joinTeam: root } = about;

const query = graphql`
  query {
    team: file(relativePath: { eq: "team-large.png" }) {
      ...teamPhoto
    }
  }
`;

const JoinTeamSection: React.FC = () => {
  const { team } = useStaticQuery(query);

  return (
    <S.Wrapper>
      <S.Image fluid={team.childImageSharp.fluid}>
        <h3>{root.title}</h3>
        <p>{root.subtitle}</p>
        <S.Link href="http://jobs.superfluid.finance/" text={"careers"}>
          {root.ctaTitle}
        </S.Link>
      </S.Image>
    </S.Wrapper>
  );
};

export default JoinTeamSection;
