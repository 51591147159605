import React from "react";

import { home } from "../../assets/strings";
import * as S from "./ProtocolSectionPageD.style";
import ProtocolSectionBase from "./ProtocolSectionBase";
import RealTimeBalance from "../../components/RealTimeBalance";
import useDimensions from "../../hooks/useDimensions";
import { breakPoints } from "../../utils/theme";
import { ProtocolSectionProps as Props } from "../../types";

const { protocolSection: root } = home;

const ProtocolSectionPageD: React.FC<Props> = ({
  onSetIndex,
  index,
  data: { balance, timeElapsed },
}) => {
  const slide = root.slides["D"];

  const { width } = useDimensions();

  const isMobile = width < breakPoints.md;

  const mappedDescriptions = slide.descriptions.map((str) => (
    <p dangerouslySetInnerHTML={{ __html: str }} />
  ));

  return (
    <ProtocolSectionBase index={index} onSetIndex={onSetIndex}>
      <S.Header>
        <RealTimeBalance
          isMobile={isMobile}
          value={balance}
          timer={timeElapsed}
        />
      </S.Header>
      <S.Body>
        {mappedDescriptions[0]}
        {mappedDescriptions[1]}
      </S.Body>
    </ProtocolSectionBase>
  );
};

export default ProtocolSectionPageD;
