import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { home } from "../../assets/strings";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

import Card from "../Card";
import * as S from "./GetStartedModal.style";
import { ModalType, useModalContext } from "../../context/modalContext";
import post from "../../utils/post";
import { user, identify, track } from "../../utils/user";

import "pure-react-carousel/dist/react-carousel.es.css";
import "../../assets/styles/carousel.css";

const { getStartedModal: root } = home;

const GetStartedModal: React.FC = () => {
  const [_, setModalVisibility] = useModalContext();

  const {
    streamIllustration,
    dashboardIllustration,
    financeIllustration,
  } = useStaticQuery(graphql`
    {
      streamIllustration: file(
        relativePath: { eq: "illustration-flow-modal-stream.png" }
      ) {
        ...modalIllustration
      }
      dashboardIllustration: file(
        relativePath: { eq: "illustration-flow-modal-dashboard.png" }
      ) {
        ...modalIllustration
      }
      financeIllustration: file(
        relativePath: { eq: "illustration-flow-modal-finance.png" }
      ) {
        ...modalIllustration
      }
    }
  `);

  const images = [
    streamIllustration.childImageSharp.fluid,
    dashboardIllustration.childImageSharp.fluid,
    financeIllustration.childImageSharp.fluid,
  ];

  const mappedCards = root.items.map((item, i) => ({
    index: i,
    image: images[i],
    title: item.title,
    subtitle: item.subtitle,
    ctaTitle: item.ctaTitle,
  }));

  const handleCardClick = (i: number) => {
    switch (i) {
      case 0: {
        post({ merchant: true });
        identify({ UserSegment: "merchant" });
        var hasEmail =
          localStorage.getItem("ajs_user_traits") &&
          JSON.parse(localStorage.getItem("ajs_user_traits")).hasOwnProperty(
            "email"
          );
        return hasEmail
          ? setModalVisibility({ type: ModalType.MerchantCapture })
          : setModalVisibility({ type: ModalType.MerchantEmailCapture });
      }
      case 1: {
        post({ web3user: true });
        identify({ UserSegment: "web3user" });
        track("Link Clicked", {
          linkText: "Go to App",
          url: "app.superfluid.finance",
        });
        return window.open("http://app.superfluid.finance", "_self");
      }
      case 2: {
        identify({ UserSegment: "developer" });
        post({ developer: true });
        return setModalVisibility({ type: ModalType.DeveloperCapture });
      }
    }
  };

  return (
    <>
      <S.Desktop>
        {mappedCards.map((props, i) => (
          <Card {...props} onClick={handleCardClick} />
        ))}
      </S.Desktop>
      <S.Mobile>
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={310}
          totalSlides={3}
          visibleSlides={1}
          currentSlide={0}
        >
          <Slider>
            {mappedCards.map((props, i) => (
              <Slide index={i} key={`slide-${i}`}>
                <Card {...props} onClick={handleCardClick} />
              </Slide>
            ))}
          </Slider>
          <S.Dots />
        </CarouselProvider>
      </S.Mobile>
    </>
  );
};

export default GetStartedModal;
