import React, { useEffect, useRef } from "react";
import { ModalType, useModalContext } from "../context/modalContext";
import CloseIcon from "../assets/svg/icon-close.svg";
import * as S from "./Modal.style";
import { useResize } from "../hooks/useResize";
import { breakPoints } from "../utils/theme";
import GetStartedModal from "./modals/GetStartedModal";
import MerchantCaptureModal from "./modals/DataCapture/MerchantCaptureModal";
import UserCaptureModal from "./modals/DataCapture/UserCaptureModal";
import DeveloperCaptureModal from "./modals/DataCapture/DeveloperCaptureModal";
import InvestorCaptureModal from "./modals/DataCapture/InvestorCaptureModal";
import LinkGenerationModal from "./modals/LinkGenerationModal";

const Modal: React.FC = () => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [modalType, setModalType] = useModalContext();
  const handleClosePress = () => setModalType(undefined);

  const { width } = useResize(modalRef);
  const isMobile = width < breakPoints.md;

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!!modalRef.current?.contains(event.target)) return;

      handleClosePress();
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [modalType]);

  const child = ((modalType: ModalType) => {
    switch (modalType) {
      case ModalType.GetStarted:
        return <GetStartedModal />;
      case ModalType.MerchantCapture:
        return <MerchantCaptureModal skipFirst={true} />;
      case ModalType.MerchantEmailCapture:
        return <MerchantCaptureModal />;
      case ModalType.UserCapture:
        return <UserCaptureModal />;
      case ModalType.DeveloperCapture:
        return <DeveloperCaptureModal />;
      case ModalType.InvestorCapture:
        return <InvestorCaptureModal />;
      case ModalType.LinkGeneration:
        return <LinkGenerationModal />;
      default:
        return "Modal";
    }
  })(modalType?.type);

  if (!modalType) return null;

  return (
    <S.Modal>
      <S.Container ref={modalRef}>
        {child}
        <S.CloseButton onClick={handleClosePress}>
          Close all <img src={CloseIcon} />
        </S.CloseButton>
      </S.Container>
    </S.Modal>
  );
};

export default Modal;
